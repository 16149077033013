@media screen and (min-width: 1281px) {
    .wif-container {
        width: 100%;
        margin: 75px 0;
    
        .wif-container-header {
            padding: 0 5%;
        }
    
        &-content {
            display: flex;
            padding: 0 5%;
    
            &-text {
                width: 50%;
                margin-top: 60px;
    
                &-item {
                    color: rgba(81, 81, 81, 1)5;
                    margin-bottom: 15px;
                }
            }
    
            &-img {
                width: 40%;
                margin: 60px 0 0 10%;
            }
        }
    }
}





@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .wif-container {
        width: 100%;
        margin: 75px 0;
    
        .wif-container-header {
            padding: 0 5%;
        }
    
        &-content {
            display: flex;
            padding: 0 5%;
    
            &-text {
                width: 50%;
                margin-top: 60px;
    
                &-item {
                    color: rgba(81, 81, 81, 1);
                    margin-bottom: 15px;
                }
            }
    
            &-img {
                width: 40%;
                margin: 60px 0 0 10%;
            }
        }
    }
}






@media screen and (min-width: 481px) and (max-width: 1024px) {
    .wif-container {
        width: 100%;
        margin: 75px 0;
    
        .wif-container-header {
            padding: 0 5%;
        }
    
        &-content {
            padding: 0 5%;
    
            &-text {
                margin-top: 60px;
    
                &-item {
                    color: rgba(81, 81, 81, 1);
                    margin-bottom: 15px;
                }
            }
        }
    }
}





@media screen and (min-width: 341px) and (max-width: 480px) {
    .wif-container {
        width: 100%;
        margin: 130px 0;
    
        .wif-container-header {
            padding: 0 3%;
            font-size: 36px;
            line-height: 46px;
        }
    
        &-content {
            padding: 0 3%;
    
            &-text {
                width: 100%;
                margin-top: 40px;
    
                &-item {
                    color: rgba(81, 81, 81, 1);
                    margin-bottom: 10px;
                }
            }
        }
    }
}




@media screen and (min-width: 0px) and (max-width: 340px) {
    .wif-container {
        width: 100%;
        margin: 130px 0;
    
        .wif-container-header {
            padding: 0 3%;
            font-size: 36px;
            line-height: 46px;
        }
    
        &-content {
            padding: 0 3%;
    
            &-text {
                width: 100%;
                margin-top: 40px;
    
                &-item {
                    color: rgba(81, 81, 81, 1);
                    margin-bottom: 10px;
                }
            }
    
            &-img {
                width: 100%;
                text-align: center;
                margin-top: 50px;

                img {
                    width: 100%;
                }
            }
        }
    }
}