.ap-bg {
    background-image: url('../../assets/ap_bg.png');
    background-size: cover;
    background-position: bottom;
}
@media (max-width: 1600px) {
    .ap-bg {
        background-image: url('../../assets/ap_bg.png');
        background-size: cover;
        background-position: bottom;
    }
}
@media (max-width: 481px) {
    .ap-bg {
        background-image: url('../../assets/ap_bg_mobile.png');
        background-size: cover;
        background-position: bottom;
    }
}