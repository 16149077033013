.apss-desc-header {
    font-weight: 600;
}

@media screen and (min-width: 1701px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                width: 770px;
                margin-top: 60px;
                font-size: 72px;
                line-height: 90px;
            }  
            .apss-desc {
                margin-top: 40px;
                
                &-header {
                    color: rgba(0, 208, 255, 1);
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 560px;
                    margin-top: 20px;
    
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
        img {
            height: 500px;
            position: absolute;
            right: 200px;
            top: 50px;
        }
        .ftr {
            margin-top: 200px;
        }
    }
}







@media screen and (min-width: 1441px) and (max-width: 1700px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                width: 770px;
                margin-top: 60px;
                font-size: 72px;
                line-height: 90px;
            }  
            .apss-desc {
                margin-top: 40px;
                
                &-header {
                    color: rgba(0, 208, 255, 1);
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 560px;
                    margin-top: 20px;
    
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
    
        img {
            height: 400px;
            position: absolute;
            right: 5%;
            top: 0;
            margin-top: 150px;
        }
        .ftr {
            margin-top: 200px;
        }
    }
}





@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            position: relative;
            z-index: 10;
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                width: 770px;
                margin-top: 60px;
                font-size: 72px;
                line-height: 90px;
            }  
            .apss-desc {
                margin-top: 40px;
                
                &-header {
                    color: rgba(0, 208, 255, 1);
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 560px;
                    margin-top: 20px;
    
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
    
        img {
            height: 400px;
            position: absolute;
            right: 0%;
            top: 0;
            margin-top: 50px;
            z-index: 7;
        }
    }
}






@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            position: relative;
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                margin-top: 0px;
                width: 770px;
                font-size: 72px;
                line-height: 90px;
            }  
            .apss-desc {
                margin-top: 40px;
                
                &-header {
                    color: rgba(0, 208, 255, 1);
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 560px;
                    margin-top: 20px;
    
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
    
        img {
            height: 300px;
            position: absolute;
            right: 0%;
            top: 0;
            margin-top: 100px;
            z-index: -1;
        }
    }
}




@media screen and (min-width: 851px) and (max-width: 1024px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            position: relative;
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                margin-top: 70px;
                width: 700px;
                font-size: 72px;
            }  
            .apss-desc {
                margin-top: 40px;
                
                &-header {
                    color: rgba(0, 208, 255, 1);
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 560px;
                    margin-top: 20px;
    
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
    
        img {
            height: 300px;
            position: absolute;
            right: 0%;
            top: 0;
            margin-top: 50px;
            z-index: -1;
            display: none;
        }
    }
}






@media screen and (min-width: 601px) and (max-width: 850px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            position: relative;
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                width: 100%;
                margin-top: 70px;
            }  
            .apss-desc {
                margin-top: 40px;
                
                &-header {
                    color: rgba(0, 208, 255, 1);
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 560px;
                    margin-top: 20px;
    
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
    
        img {
            height: 600px;
            position: absolute;
            right: 0%;
            top: 0;
            margin-top: -180px;
            display: none;
        }
    }
}






@media screen and (min-width: 481px) and (max-width: 600px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            position: relative;
            width: 90%;
            padding: 0 5%;
            
            .apss-header {
                width: 100%;
                margin-top: 70px;
            }  
            .apss-desc {
                margin-top: 40px;
                &-header {
                    color: rgba(0, 208, 255, 1);
                    width: 100%
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 100%;
                    margin-top: 20px;
                    &-2 {
                        margin: 15px 0 60px 0;
                    }
                }
            }
        }
    
        img {
            height: 600px;
            position: absolute;
            right: 0%;
            top: 0;
            margin-top: -180px;
            display: none;
        }
    }
}





@media screen and (min-width: 0px) and (max-width: 480px) {
    .apss-container {
        width: 100%;
        position: relative;
    
        &-content {
            position: relative;
            width: 94%;
            padding: 0 3%;
            
            .apss-header {
                width: 100%;
                margin-top: 0px;
                font-size: 48px;
                line-height: 58px;
            }  
        
            .apss-desc {
                margin-top: 30px;
                &-header {
                    color: rgba(0, 208, 255, 1);
                    width: 100%;
                    font-size: 20px;
                    line-height: 29px;
                }
                &-text {
                    color: rgba(81, 81, 81, 1);
                    width: 100%;
                    margin-top: 10px;
                    &-2 {
                        margin: 15px 0 0px 0;
                    }
                }
            }
        }
    
        img {
            height: 400px;
            position: absolute;
            right: 0%;
            top: 0;
            margin-top: -100px;
            display: none;
        }
    }
}