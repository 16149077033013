.footer-container {
    background-color: #3F778A;
    color: white
}
.company-registered {
    margin-bottom: 40px;
}

::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.ta-left {
    text-align: left;
}
.ta-right {
    text-align: right;
}
.ta-center {
    text-align: center;
}

@media screen and (min-width: 1441px) {
    .footer-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 9;
        padding: 0 0 2% 0;

        &-links {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;
            display: flex;
            .links-docs {
                width: 30%;

                &-logo {
                    margin-bottom: 14px;

                    img {
                        cursor: pointer;
                        height: 50px;
                    }
                }
            }
            .links-other {
                width: 70%;
                display: flex;

                &-relation {
                    width: 50%;
                    padding-left: 1%;
                }

                &-info {
                    width: 50%;
                    padding-left: 1%;
                }
            }
            .sent {
                display: block;
                color: #fff;
                margin-bottom: 5px;
                cursor: pointer;
            }
        }

        &-footer {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;
            display: flex;

            &-notice {
                width: 100%;
                margin-top: 30px;
                .notice-send {
                    display: flex;
                    margin-top: 10px;

                    &-input {
                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        width: 300px;
                        height: 45px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        line-height: 19px;
                        padding-left: 13px;

                        .input {
                            background-color: transparent;
                            border: none;
                            color: rgba(255, 255, 255, 0.5);
                        }
                        .input:focus {
                            outline: 0;
                        }
                    }

                    &-button {
                        margin-left: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 116px;
                        height: 45px;
                        position: relative;
                        border-radius: 100px;
                        color: #000000;
                        background: white;
                        box-shadow: 0px 4px 6px #2F5C6B;
                    }
                }
            }

            &-containers {
                display: flex;
                width: 100%;

                .about {
                    width: 30%;
                    margin-top: 15px;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    &-images {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .restriction {
                    padding-left: 1%;
                    width: 34%;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                }

                .warning {
                    padding-left: 1%;
                    width: 34%;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    .copyright {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}





// =============   < 1440   ==============



@media screen and (max-width: 1441px) and (min-width: 1025px) {
    .footer-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 9;
        padding: 0 0 2% 0;

        &-links {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;
            display: flex;

            .links-docs {
                width: 30%;
                gap: 5px;
                &-logo {
                    margin-bottom: 14px;
                    img {
                        cursor: pointer;
                        height: 50px;
                    }
                }
            }

            .links-other {
                width: 70%;
                display: flex;
                &-relation {
                    width: 50%;
                    padding-left: 1%;
                    gap: 5px;
                }
                &-info {
                    width: 50%;
                    padding-left: 1%;
                    gap: 5px;
                }
            }
            .sent {
                display: block;
                color: #fff;
                margin-bottom: 5px;
                cursor: pointer;
            }

        }

        &-footer {
            padding: 50px 5% 0 5%;
            z-index: 11;
            position: relative;
            display: flex;

            &-notice {
                width: 100%;
                margin-top: 30px;
                .notice-send {
                    margin-top: 10px;
                    &-input {
                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        width: 90%;
                        height: 45px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        line-height: 19px;
                        padding-left: 13px;
                        .input {
                            background-color: transparent;
                            border: none;
                            color: rgba(255, 255, 255, 0.5);

                            width: 90%;
                        }
                        .input:focus {
                            outline: 0;
                        }
                    }

                    &-button {
                        margin-top: 10px;
                        margin-right: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 116px;
                        height: 45px;
                        position: relative;
                        border-radius: 100px;
                        color: #000000;
                        background: white;
                        box-shadow: 0px 4px 6px #2F5C6B;
                    }
                }
            }

            &-containers {
                display: flex;
                width: 100%;

                .about {
                    width: 30%;
                    margin-top: 15px;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    &-images {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .restriction {
                    padding-left: 1%;
                    width: 34%;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                }

                .warning {
                    padding-left: 1%;
                    width: 34%;

                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    .copyright {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}





// =============   < 1024   ==============



@media screen and (max-width: 1025px) and (min-width: 769px) {
    .footer-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 9;
        padding: 20px 0 2% 0;

        &-links {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;
            display: flex;

            .links-docs {
                width: 36%;
                gap: 10px;
                &-logo {
                    margin-bottom: 14px;
                    img {
                        cursor: pointer;
                        height: 50px;
                    }
                }
            }

            .links-other {
                width: 64%;
                display: flex;
                &-relation {
                    width: 50%;
                    padding-left: 1%;
                    gap: 10px;
                }
                &-info {
                    width: 50%;
                    padding-left: 1%;
                    gap: 10px;
                }
            }

            .sent {
                display: block;
                color: #fff;
                margin-bottom: 5px;
                cursor: pointer;
            }

        }

        &-footer {
            padding: 0% 5% 0 5%;
            z-index: 11;
            position: relative;

            &-notice {
                width: 100%;
                margin-top: 30px;

                .notice-text {
                    font-size: 20px;
                    line-height: 21px;
                    margin: auto 0 auto 0;
                    width: 100%;
                }

                .notice-send {
                    margin-top: 10px;

                    &-input {
                        color: #676767;
                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        width: 90%;
                        height: 45px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        line-height: 19px;
                        padding-left: 13px;
                        .input {
                            background-color: transparent;
                            border: none;
                            color: rgba(255, 255, 255, 0.5);

                            width: 90%;
                        }
                        .input:focus {
                            outline: 0;
                        }
                    }
                    &-button {
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 150px;
                        height: 45px;
                        position: relative;
                        border-radius: 100px;
                        color: #000000;
                        background: white;
                        box-shadow: 0px 4px 6px #2F5C6B;
                    }
                }
            }

            &-containers {
                display: flex;
                width: 100%;
                margin-top: 40px;

                .about {
                    width: 36%;
                    margin-top: 15px;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    &-images {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .restriction {
                    padding-left: 1%;
                    width: 32%;

                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                }

                .warning {
                    padding-left: 1%;
                    width: 32%;

                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    .copyright {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}






// =============   < 768   ==============



@media screen and (max-width: 769px) and (min-width: 481px) {
    .footer-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 9;
        padding: 20px 0 2% 0;

        &-links {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;
            .links-docs {
                width: 100%;
                gap: 10px;
                &-logo {
                    margin-bottom: 14px;
                    img {
                        cursor: pointer;
                        height: 50px;
                    }
                }
            }
            .links-other {
                width: 100%;
                display: flex;
                margin-top: 30px;
                &-relation {
                    width: 50%;
                    padding-left: 1%;
                    gap: 10px;
                }
                &-info {
                    width: 50%;
                    padding-left: 1%;
                    gap: 10px;
                }
            }
            .sent {
                display: block;
                color: #fff;
                margin-bottom: 5px;
                cursor: pointer;
            }

        }

        &-footer {
            padding: 0% 5% 0 5%;
            z-index: 11;
            position: relative;

            &-notice {
                width: 100%;
                margin-top: 40px;

                .notice-text {
                    font-size: 20px;
                    line-height: 21px;
                }

                .notice-send {
                    margin-top: 10px;
                    width: 100%;
                    display: flex;

                    &-input {
                        color: #676767;

                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        width: 70%;
                        height: 45px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        line-height: 19px;
                        padding-left: 13px;

                        .input {
                            background-color: transparent;
                            border: none;
                            color: rgba(255, 255, 255, 0.5);

                            width: 90%;
                        }

                        .input:focus {
                            outline: 0;
                        }
                    }

                    &-button {
                        margin-left: auto;
                        margin-right: auto;
                        // margin-top: 15px;
                        margin-left: 20px;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 40%;
                        height: 45px;

                        position: relative;
                        border-radius: 100px;
                        color: #000000;
                        background: white;
                        box-shadow: 0px 4px 6px #2F5C6B;
                    }
                }
            }

            &-containers {
                width: 100%;
                margin-top: 30px;

                .about {
                    width: 100%;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    &-images {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .restriction {
                    width: 100%;
                    margin-top: 30px;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                }

                .warning {
                    width: 100%;
                    margin-top: 30px;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    .copyright {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}






// =============   < 480   ==============



@media screen and (max-width: 481px) and (min-width: 361px) {
    .footer-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 9;
        padding: 20px 0 2% 0;

        &-links {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;

            .links-docs {
                width: 100%;
                gap: 15px;

                &-logo {
                    margin-bottom: 20px;

                    img {
                        height: 40px;
                    }
                }
            }

            .links-other {
                margin-top: 30px;
                width: 100%;
                display: flex;

                &-relation {
                    width: 50%;

                }

                &-info {
                    width: 50%;
                }
            }

            .sent {
                display: block;
                color: #fff;
                margin-bottom: 15px;
                cursor: pointer;
                font-size: 14px;
                line-height: 17px;
            }

        }

        &-footer {
            padding: 0% 5% 0 5%;
            z-index: 11;
            position: relative;

            &-notice {
                width: 100%;
                margin-top: 30px;

                .notice-text {
                    font-size: 16px;
                    line-height: 21px;
                    width: 100%;
                    margin: auto 0 auto 0;
                }

                .notice-send {
                    margin-left: auto;
                    width: 100%;

                    &-input {
                        color: #676767;

                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        width: 96%;
                        height: 45px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        line-height: 19px;
                        padding-left: 15px;
                        margin-top: 10px;


                        .input {
                            background-color: transparent;
                            border: none;
                            color: rgba(255, 255, 255, 0.5);

                            width: 90%;
                        }

                        .input:focus {
                            outline: 0;
                        }
                    }

                    &-button {
                        margin-top: 10px;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 100%;
                        height: 45px;

                        position: relative;
                        border-radius: 100px;
                        color: #000000;
                        background: white;
                        box-shadow: 0px 4px 6px #2F5C6B;
                    }
                }
            }

            &-containers {
                width: 100%;
                margin-top: 20px;

                .about {
                    width: 100%;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    &-images {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;

                        img {
                            height: 35px;
                        }
                    }
                }
                .restriction {
                    width: 100%;
                    margin-top: 10px;

                    &-header {
                        font-weight: 400;
                    }

                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 5px;
                    }
                }
                .warning {
                    width: 100%;
                    margin-top: 10px;

                    &-header {
                        font-weight: 400;
                    }

                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 5px;
                    }

                    .copyright {
                        margin: 20px 0 20px 0;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}




// =============   < 360   ==============



@media screen and (max-width: 361px) and (min-width: 0px) {
    .footer-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        z-index: 9;
        padding: 20px 0 2% 0;

        &-links {
            padding: 2% 5% 0 5%;
            z-index: 11;
            position: relative;

            .links-docs {
                width: 100%;
                display: grid;
                gap: 15px;

                &-logo {
                    margin-bottom: 0px;

                    img {
                        height: 40px;
                    }
                }
            }

            .links-other {
                margin-top: 60px;
                width: 100%;

                &-relation {
                    width: 100%;
                    display: grid;
                    gap: 15px;
                }

                &-info {
                    margin-top: 50px;
                    display: grid;
                    width: 100%;
                    gap: 15px;
                }
            }

            .sent {
                display: block;
                color: #fff;
                margin-bottom: 5px;
                cursor: pointer;
                font-size: 14px;
                line-height: 17px;
            }

        }

        &-footer {
            padding: 0% 5% 0 5%;
            z-index: 11;
            position: relative;

            &-notice {
                width: 100%;
                margin-top: 40px;

                .notice-text {
                    font-size: 16px;
                    line-height: 21px;
                    width: 100%;
                    margin: auto 0 auto 0;
                }

                .notice-send {
                    margin-left: auto;
                    width: 100%;

                    &-input {
                        color: #676767;

                        border: 1px solid #FFFFFF;
                        border-radius: 30px;
                        width: 96%;
                        height: 45px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        line-height: 19px;
                        padding-left: 10px;
                        margin-top: 10px;


                        .input {
                            background-color: transparent;
                            border: none;
                            color: rgba(255, 255, 255, 0.5);

                            width: 90%;
                        }

                        .input:focus {
                            outline: 0;
                        }
                    }

                    &-button {
                        margin-top: 10px;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 100%;
                        height: 45px;

                        position: relative;
                        border-radius: 100px;
                        color: #000000;
                        background: white;
                        box-shadow: 0px 4px 6px #2F5C6B;
                    }
                }
            }

            &-containers {
                width: 100%;
                margin-top: 20px;

                .about {
                    width: 100%;
                    &-header {
                        font-weight: 400;
                    }
                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 10px;
                    }
                    &-images {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .restriction {
                    width: 100%;
                    margin-top: 10px;

                    &-header {
                        font-weight: 400;
                    }

                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 5px;
                    }
                }

                .warning {
                    width: 100%;
                    margin-top: 10px;

                    &-header {
                        font-weight: 400;
                    }

                    &-desc {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                        margin-top: 5px;
                    }

                    .copyright {
                        margin: 20px 0 20px 0;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

