.why-pix__wrap {
  margin-top: 150px;
}

.col-5 {
  max-width: 560px;
  width: 100%;
}

.why-pix__title {
  font-family: "Bebas";
  font-size: 64px;
  font-weight: 400;
}

.why-pix__text {
  font-family: 'Inter';
  font-size: 16px;
  line-height: 24px;
  color: #515151;
}

.why-pix__list {
  .why-pix__item {
    font-size: 14px;
    color: #3F778A;
    line-height: 2;
    &:first-child {
      margin-top: 30px;
    }
    &:last-child {
      margin-bottom: 30px;
    }
    span {
      font-family: 'Inter';
      color: black;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
}

.why-pix__link {
  position: relative;
  display: inline-flex;
  font-family: "Inter";
  font-size: 16px;
  color: white;
  padding: 16px 30px;
  background: #3F778A;
  border-radius: 100px;
  text-decoration: none;
  margin-top: 15px;
}

@media (max-width: 480px) {
  .values__title {
    font-size: 36px;
    line-height: 36px;
  }
  .why-pix__list {
    .why-pix__item {
      span {
        font-size: 20px;
      }
    }
  }
}
