.ec-bg {
    background-image: url('../../assets/earnings-calendar/ss_bg.png');
    background-size:cover;
    background-position: top right;
}
@media (max-width: 481px) {
    .ec-bg {
        background-image: url('../../assets/earnings-calendar/ss_bg_mobile.png');
        background-size: cover;
        background-position: bottom right;
    }
}