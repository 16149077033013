.card-img img {
    transition: scale .5s;
}

@media screen and (min-width: 1025px) {
    .ng-container {
        width: 100%;

        .header {
            padding: 150px 5% 0 5%;
            width: 620px;
            &-anlang {
                width: 850px;
            }
        }

        &-content {
            margin: 50px 5% 0 5%;

            display: grid;
            grid-template: repeat(3, 0fr) / repeat(3, 1fr);
            gap: 50px 90px;

            .card {
                display: inline-block;
                width: 100%;
                align-self: center;
                justify-self: center;
                position: relative;

                &-img {
                    position: absolute;
                    width: 100%;
                    z-index: 99;
                    width: 100%;

                    img {
                        display: block;
                        margin: 0 auto 0 auto;
                        width: 100px;
                    }
                }

                &-content { 
                    box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                    z-index: 9;
                    position: relative;
                    margin-top: 40px;
                    width: 100%;
                    height: 180px;
                    overflow: hidden;
                    border-radius: 20px;
                    background-color: rgba(217, 217, 217, 0.01);
                    justify-content: center;
                    display: flex;
                    &-text {
                        width: 80%;
                        text-align: center;
                        margin-top: 70px;
                        position: relative;
                        z-index: 9;
                    }
                }
            }

            .card:hover .card-img img{
                scale: 1.2;
            }
        }
    }
}




// ============ < 1024 ==============



@media screen and (max-width: 1025px) and (min-width: 481px) {
    .ng-container {
        width: 100%;

        .header {
            padding: 150px 5% 0 5%;
            width: 90%;
        }

        &-content {
            margin: 50px 5% 0 5%;

            display: grid;
            grid-template: repeat(3, 0fr) / repeat(2, 1fr);
            gap: 30px 20px;

            .card {
                display: inline-block;
                width: 100%;
                align-self: center;
                justify-self: center;
                position: relative;

                &-img {
                    position: absolute;
                    width: 100%;
                    z-index: 99;
                    width: 100%;

                    img {
                        display: block;
                        margin: 0 auto 0 auto;
                        width: 100px;
                    }
                }

                &-content {
                    box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                    z-index: 9;
                    position: relative;
                    margin-top: 40px;
                    width: 100%;
                    height: 180px;
                    overflow: hidden;
                    border-radius: 20px;
                    background-color: rgba(217, 217, 217, 0.01);
                    display: flex;
                    justify-content: center;
                    &-text {
                        width: 80%;
                        text-align: center;
                        margin-top: 70px;
                        font-size: 20px;
                        position: relative;
                        z-index: 9;
                    }
                }
            }

            .card:hover .card-img img{
                scale: 1.2;
            }
        }
    }
}





// ============ < 480 ==============



@media screen and (max-width: 481px) and (min-width: 361px) {
    .ng-container {
        width: 100%;

        .header {
            padding: 150px 3% 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            margin: 40px 3% 0 3%;

            display: grid;
            grid-template: repeat(6, 0fr) / repeat(1, 1fr);
            gap: 20px 20px;

            .card {
                display: inline-block;
                width: 100%;
                align-self: center;
                justify-self: center;
                position: relative;
                // height: 150px;

                &-img {
                    position: absolute;
                    width: 100%;
                    z-index: 99;
                    width: 100%;

                    img {
                        display: block;
                        margin: 0 auto 0 auto;
                        width: 80px;
                    }
                }

                &-content {
                    box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                    z-index: 9;
                    position: relative;
                    margin-top: 30px;
                    width: 100%;

                    height: 100px;   // here

                    overflow: hidden;
                    border-radius: 20px;
                    background-color: rgba(217, 217, 217, 0.01);

                    &-text {
                        width: 100%;
                        text-align: center;

                        margin-top: 40px;
                        position: relative;
                        z-index: 9;

                        font-size: 16px;
                        line-height: 36px;
                    }
                }
            }

            .card:hover .card-img img{
                scale: 1.2;
            }
        }
    }
}






// ============ < 360 ==============



@media screen and (max-width: 361px) and (min-width: 0px) {
    .ng-container {
        width: 100%;

        .header {
            padding: 150px 3% 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            margin: 30px 3% 0 3%;

            display: grid;
            grid-template: repeat(6, 0fr) / repeat(1, 1fr);
            gap: 20px 20px;

            .card {
                display: inline-block;
                width: 100%;
                align-self: center;
                justify-self: center;
                position: relative;
                // height: 150px;

                &-img {
                    position: absolute;
                    width: 100%;
                    z-index: 99;
                    width: 100%;

                    img {
                        display: block;
                        margin: 0 auto 0 auto;
                        width: 80px;
                    }
                }

                &-content {
                    box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                    z-index: 9;
                    position: relative;
                    margin-top: 30px;
                    width: 100%;

                    height: 100px;   // here

                    overflow: hidden;
                    border-radius: 20px;
                    background-color: rgba(217, 217, 217, 0.01);

                    &-text {
                        width: 100%;
                        text-align: center;

                        margin-top: 40px;
                        position: relative;
                        z-index: 9;

                        font-size: 16px;
                        line-height: 36px;
                    }
                }
            }

            .card:hover .card-img img{
                scale: 1.2;
            }
        }
    }
}