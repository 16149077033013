.qr-container {
    margin: 150px 0;

    &-header {
        text-transform: uppercase;
        padding: 0 5%;
        color: #3F778A;
        font-family: 'Bebas';
        font-size: 64px;
    }

    &-text {
        margin-top: 40px;
        padding: 0 5%;
        display: flex;
        justify-content: space-between;
        color: #515151;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &-item {
            width: 47%;
            &-par {
                margin-bottom: 15px;
            }
        }
    }

    &-table {
        padding: 0 0%;
        margin-top: calc(80px - 15px);
    }
}

@media (max-width: 480px) {
    .qr-container {
        margin: 100px 0;
    }
    .qr-container-header {
        padding: 0 3%;
        font-size: 36px;
        line-height: 46px;
    }
    .qr-container-text {
        padding: 0 3%;
        display: block;
    }
    .qr-container-text-item {
        width: 100%;
    }
}