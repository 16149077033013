.ecss-container {
    justify-content: center;
    text-align: center;
}
.ecss-container-header {
    padding: 0 5%;
    margin-top: 150px;
    font-family: 'Bebas';
    font-style: normal;
    font-weight: 400;
    font-size: 86px;
    line-height: 103px;
    color: #3F778A;
    text-align: center;
}
.ecss-container-desc {
    width: 580px;
    margin: 20px auto 0 auto;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}
.ecss-container-btn {
    background: #3F778A;
    border-radius: 100px;
    color: white;
    padding: 18px 30px;
    display: inline-flex;
    margin: 60px auto 440px auto;
    position: relative;
    font-family: "Inter";
    font-size: 16px;
}

@media (max-width: 640px) {
    .ecss-container-desc {
        padding: 0 5%;
        width: 90%;
    }
}

@media (max-width: 480px) {
    .ecss-container-header {
        font-size: 48px;
        line-height: 58px;
        padding: 0 3%;
        margin-top: 70px;
    }
    .ecss-container-desc {
        font-size: 20px;
        line-height: 30px;
        padding: 0 3%;
        width: 94%;
    }
    .ecss-container-btn {
        margin-bottom: 360px;
    }
}