.tab-content-left-header, .tab-content-right-header {
    color: white;
}

@media screen and (min-width: 1025px) {
    .ut-container {
        width: 100%;
        margin: 150px 0 0 0;
    
        .ut-container-header {
            padding: 0 5% 0 5%;
        }
    
        &-content {
            padding: 40px 0% 0 0%;

            .tab {
                padding: 30px 5%;
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    padding: 0 5% 0 5%;
                    display: flex;
                    height: 90px;
    
                    &-img {
                        width: 90px;
                        height: 90px;
                        text-align: center;
    
                        img {
                            height: 90px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 80px 0 60px;
                        line-height: 80px;
                        font-size: 48px;
                        height: 90px;
                    }
    
                    &-arrow {
                        line-height: 85px;
                        position: absolute;
                        left: 700px;
    
                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    margin: 0 5% 0 5%;
    
                    &-par {
                        margin-left: 150px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 150px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 36px;
                            line-height: 48px;
    
                            width: 160px;
    
                            padding: 20px 0 20px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 30px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 60px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 36px;
                            line-height: 48px;
    
                            width: 360px;
                            padding: 20px 0 20px 0;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
            }
        }
    }
}





@media screen and (min-width: 801px) and (max-width: 1024px) {
    .ut-container {
        width: 100%;
        margin: 50px 0 0 0;
    
        .ut-container-header {
            padding: 0 5% 0 5%;
        }
    
        &-content {
            padding: 40px 0% 0 0%;
    
            .tab {
                padding: 30px 5%;
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    padding: 0 5% 0 5%;
                    display: flex;
                    height: 90px;
    
                    &-img {
                        width: 90px;
                        height: 90px;
                        text-align: center;
    
                        img {
                            height: 90px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 80px 0 60px;
                        line-height: 80px;
                        height: 90px;
                        font-size: 48px;
                    }
    
                    &-arrow {
                        line-height: 85px;
                        position: absolute;
                        left: 600px;
    
                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    margin: 0 5% 0 5%;
    
                    &-par {
                        margin-left: 150px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 36px;
                            line-height: 48px;
    
                            width: 160px;
    
                            padding: 20px 0 20px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 60px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 36px;
                            line-height: 48px;
    
                            width: 360px;
                            padding: 20px 0 20px 0;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
            }
        }
    }
}





@media screen and (min-width: 681px) and (max-width: 800px) {
    .ut-container {
        width: 100%;
        margin: 0px 0 0 0;
    
        .ut-container-header {
            padding: 0 5% 0 5%;
        }
    
        &-content {
            // padding: 40px 5% 0 5%;
            margin-top: 20px;
    
            .tab {
                padding: 30px 5%;
                
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    padding: 0 5% 0 5%;
                    display: flex;
                    height: 90px;
    
                    &-img {
                        width: 90px;
                        height: 90px;
                        text-align: center;
    
                        img {
                            height: 90px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 80px 0 60px;
                        line-height: 80px;
                        height: 90px;
                        font-size: 44px;
                    }
    
                    &-arrow {
                        line-height: 85px;
                        position: absolute;
                        left: 600px;
    
                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    margin: 0 5% 0 5%;
                    flex-direction: column-reverse;
    
                    &-par {
                        margin-left: 150px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 150px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 44px;
    
                            width: 160px;
    
                            padding: 20px 0 20px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 150px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 44px;
    
                            width: 360px;
                            padding: 20px 0 20px 0;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
            }
        }
    }
}









@media screen and (min-width: 531px) and (max-width: 680px) {
    .ut-container {
        width: 100%;
        margin: 0px 0 0 0;
    
        .ut-container-header {
            padding: 0 5% 0 5%;
        }
    
        &-content {
            // padding: 40px 5% 0 5%;
            margin-top: 20px;
    
            .tab {
                padding: 30px 0;
                
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    padding: 0 5% 0 5%;
                    display: flex;
                    height: 70px;
    
                    &-img {
                        width: 70px;
                        height: 70px;
                        text-align: center;
    
                        img {
                            height: 70px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 40px 0 40px;
                        line-height: 70px;
                        height: 70px;
                        font-size: 44px;
                    }
    
                    &-arrow {
                        line-height: 75px;
                        position: absolute;
                        left: 500px;
    
                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    margin: 0 5% 0 5%;
                    flex-direction: column-reverse;
    
                    &-par {
                        margin: 20px 0 0 0;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 50px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 44px;
    
                            width: 160px;
    
                            padding: 20px 0 20px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 50px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 44px;
    
                            width: 360px;
                            padding: 20px 0 20px 0;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
            }
        }
    }
}








@media screen and (min-width: 481px) and (max-width: 530px) {
    .ut-container {
        width: 100%;
        margin: 0px 0 0 0;
    
        .ut-container-header {
            padding: 0 5% 0 5%;
        }
    
        &-content {
            // padding: 40px 5% 0 5%;
            margin-top: 20px;
    
            .tab {
                padding: 30px 0;
                
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    padding: 0 5% 0 5%;
                    display: flex;
                    height: 70px;
    
                    &-img {
                        width: 70px;
                        height: 70px;
                        text-align: center;
    
                        img {
                            height: 70px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 20px 0 10px;
                        line-height: 70px;
                        height: 70px;
                        font-size: 44px;
                    }
    
                    &-arrow {
                        line-height: 75px;
                        position: absolute;
                        left: 450px;
    
                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    margin: 0 5% 0 5%;
                    flex-direction: column-reverse;
    
                    &-par {
                        margin: 20px 0 0 0;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 50px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 44px;
    
                            width: 160px;
    
                            padding: 20px 0 20px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 50px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 44px;
    
                            width: 360px;
                            padding: 20px 0 20px 0;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
            }
        }
    }
}







@media screen and (min-width: 381px) and (max-width: 480px) {
    .ut-container {
        width: 100%;
        margin: 100px 0 0 0;
    
        .ut-container-header {
            padding: 0 3% 0 3%;
            font-size: 36px;
            // line-height: 30px;
        }
    
        &-content {
            padding: 0 0% 0 0%;
            margin-top: 0px;
    
            .tab {
                padding: 30px 3%;
                
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    padding: 0 3% 0 3%;
                    display: flex;
                    height: 50px;
    
                    &-img {
                        width: 50px;
                        height: 50px;
                        text-align: center;
    
                        img {
                            height: 50px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 50px 0 30px;
                        line-height: 50px;
                        font-size: 32px;
                        height: 50px;
                    }
    
                    &-arrow {
                        line-height: 45px;
                        position: absolute;
                        left: 350px;

                        img {
                            height: 20px;
                        }

                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    margin: 0 3% 0 3%;
                    flex-direction: column-reverse;
    
                    &-par {
                        margin: 20px 0px 0 20px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 20px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 48px;
    
    
                            padding: 10px 0 10px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 20px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 48px;
    
                            padding: 10px 0 10px 0;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
                .tab-header-arrow {
                    img {
                        height: 12px;
                    }
                }
            }
        }
    }
}





@media screen and (min-width: 311px) and (max-width: 380px) {
    .ut-container {
        width: 100%;
        margin: 100px 0 0 0;
    
        .ut-container-header {
            padding: 0 3% 0 3%;
            font-size: 36px;
            // line-height: 30px;
        }
    
        &-content {
            padding: 0 0% 0 0%;
            margin-top: 0px;
    
            .tab {
                padding: 30px 3%;
                
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    // padding: 0 3% 0 3%;
                    display: flex;
                    height: 40px;
    
                    &-img {
                        width: 40px;
                        height: 40px;
                        text-align: center;
    
                        img {
                            height: 40px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 30px 0 20px;
                        line-height: 40px;
                        font-size: 32px;
                        height: 40px;
                    }
    
                    &-arrow {
                        line-height: 30px;
                        position: absolute;
                        left: 300px;

                        img {
                            height: 20px;
                        }

                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    // margin: 0 3% 0 3%;
                    flex-direction: column-reverse;
    
                    &-par {
                        margin: 20px 0px 0 10px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 10px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 48px;
    
    
                            padding: 10px 0 10px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 160px;
                            // height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 18px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 10px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 48px;
    
                            padding: 10px 0 10px 0;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
                .tab-header-arrow {
                    img {
                        height: 12px;
                    }
                }
            }
        }
    }
}





@media screen and (min-width: 0px) and (max-width: 310px) {
    .ut-container {
        width: 100%;
        margin: 100px 0 0 0;
    
        .ut-container-header {
            padding: 0 0% 0 0%;
            font-size: 36px;
            // line-height: 30px;
        }
    
        &-content {
            padding: 0 3% 0 3%;
            margin-top: 0px;
    
            .tab {
                padding: 30px 3%;
                
                border-bottom: 1px solid;
    
                border-image: linear-gradient(
                    to left,
                    white,
                    rgba(63, 119, 138, 1),
                    white
                ) 100% 0 100% 0/0 0 1px 0 stretch;
    
                &-header {
                    cursor: pointer;
                    color: rgba(63, 119, 138, 1);
                    // padding: 0 3% 0 3%;
                    display: flex;
                    height: 40px;
    
                    &-img {
                        width: 40px;
                        height: 40px;
                        text-align: center;
    
                        img {
                            height: 30px;
                        }
                        #img-white {
                            display: none;
                        }
                    }
    
                    &-text {
                        margin: 0 20px 0 10px;
                        line-height: 45px;
                        font-size: 32px;
                        height: 40px;
                    }
    
                    &-arrow {
                        line-height: 35px;

                        img {
                            height: 20px;
                        }

                        .rotate-90 { 
                            transform: rotate(-90deg);
                        }
                    }
                }
    
                &-content {
                    display: none;
                    // margin: 0 3% 0 3%;
                    flex-direction: column-reverse;
    
                    &-par {
                        margin: 20px 0px 0 10px;
    
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 0.7);
                    }
    
                    &-left {
                        margin-left: 10px;
    
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 48px;
    
    
                            padding: 10px 0 10px 0;
                        }
    
                        &-content {
    
                            ul {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 0.7);
                                margin: 0;
                                padding-left: 20px;
        
                                li {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
    
                        &-btn {
                            position: relative;
                            z-index: 10;
            
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            
                            width: 100px;
                            height: 30px;
                            margin-top: 40px;
                            
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: 14px 30px;
                            gap: 10px;
                            border-radius: 100px;
                            
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                        }
                    }
    
                    &-right {
                        margin-left: 10px;
                        
                        &-header {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 48px;
    
                            padding: 10px 0 10px 0;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
    
                .show-content {
                    display: flex;
                }
            }
    
            .selected-tab {
                padding: 40px 5%;
                background-color: rgba(63, 119, 138, 1);
            
                #img-dark {
                    display: none;
                }
                #img-white {
                    display: block;
                }
                .tab-header {
                    color: white;
                }
                .tab-header-arrow {
                    img {
                        height: 12px;
                    }
                }
            }
        }
    }
}