.etss-container {
    padding-bottom: 50px;
}
.etss-container-first-text {
    color: black;
}

@media screen and (min-width: 681px) {
    .etss-container {
        position: relative;
        width: 100%;
    
        &-first {
            z-index: 3;
            position: relative;
            padding: 0 5%;
            width: 90%;
            text-align: center;
    
            .etss-container-first-header {
                margin-top: 50px;
            }
            .etss-container-first-text {
                margin-top: 20px;
            }
            &-btn {
                position: relative;
                margin: 60px auto 0 auto;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 239px;
                height: 68px;
                border-radius: 100px;
                color: white;
                background: #3F778A;
                cursor: pointer;
            }
        }
    
        &-second {
            z-index: 3;
            position: relative;
            padding: 0 5%;
            width: 90%;
            margin-top: 180px;
    
            &-header {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #00D0FF;
            }
    
            .etss-container-second-links {
                color: rgba(0, 0, 0, 1);
                margin-top: 60px;
    
                &-item {
                    text-decoration: underline;
                    cursor: pointer;
                    display: block;
                    width: 660px;
                    margin-bottom: 10px;
                }
                &-item:hover {
                    font-weight: 600;
                }
            }
        }
    
        &-img {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            text-align: center;
            
            img {
                width: 1175px;
            
            }
        }
    }
}




@media screen and (min-width: 481px) and (max-width: 680px) {
    .etss-container {
        position: relative;
        width: 100%;
    
        &-first {
            z-index: 3;
            position: relative;
            padding: 0 5%;
            width: 90%;
            text-align: center;
    
            .etss-container-first-header {
                margin-top: 50px;
            }
            .etss-container-first-text {
                margin-top: 20px;
            }
            &-btn {
                position: relative;
                margin: 60px auto 0 auto;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 239px;
                height: 68px;
                border-radius: 100px;
                color: white;
                background: #3F778A;;
                cursor: pointer;
            }
        }
    
        &-second {
            z-index: 3;
            position: relative;
            padding: 0 5%;
            width: 90%;
            margin-top: 180px;
    
            &-header {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #00D0FF;
            }
    
            .etss-container-second-links {
                color: rgba(0, 0, 0, 1);
                margin-top: 60px;
    
                &-item {
                    text-decoration: underline;
                    cursor: pointer;
                    display: block;
                    margin-bottom: 10px;
                }
                &-item:hover {
                    font-weight: 600;
                }
            }
        }
    
        &-img {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            text-align: center;
            
            img {
                width: 1175px;
            
            }
        }
    }
}







@media screen and (min-width: 321px) and (max-width: 480px) {
    .etss-container {
        position: relative;
        width: 100%;
    
        &-first {
            z-index: 3;
            position: relative;
            padding: 0 3%;
            width: 94%;
            text-align: center;
    
            .etss-container-first-header {
                margin-top: 30px;
                font-size: 40px;
                line-height: 50px;
            }
            .etss-container-first-text {
                margin-top: 20px;
                line-height: 30px;
                font-size: 20px;
            }
            &-btn {
                position: relative;
                margin: 60px auto 0 auto;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 239px;
                height: 68px;
                border-radius: 100px;
                color: white;
                background: #3F778A;;
                cursor: pointer;
            }
        }
    
        &-second {
            z-index: 3;
            position: relative;
            padding: 0 3%;
            width: 94%;
            margin-top: 120px;
    
            &-header {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #00D0FF;
            }
            .etss-container-second-links {
                color: rgba(0, 0, 0, 1);
                margin-top: 20px;
    
                &-item {
                    text-decoration: underline;
                    cursor: pointer;
                    display: block;
                    margin-bottom: 5px;
                    font-size: 18px;
                }
                &-item:hover {
                    font-weight: 600;
                }
            }
        }
    
        &-img {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            text-align: center;
            
            img {
                width: 1175px;
            
            }
        }
    }
}





@media screen and (min-width: 0px) and (max-width: 320px) {
    .etss-container {
        position: relative;
        width: 100%;
    
        &-first {
            z-index: 3;
            position: relative;
            padding: 0 3%;
            width: 94%;
            text-align: center;
    
            .etss-container-first-header {
                margin-top: 0px;
                font-size: 40px;
                line-height: 50px;
            }
            .etss-container-first-text {
                margin-top: 20px;
                line-height: 30px;
                font-size: 20px;
            }
            &-btn {
                position: relative;
                margin: 60px auto 0 auto;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 239px;
                height: 68px;
                border-radius: 100px;
                color: white;
                background: #3F778A;;
                cursor: pointer;
            }
        }
    
        &-second {
            z-index: 3;
            position: relative;
            padding: 0 3%;
            width: 94%;
            margin-top: 80px;
    
            &-header {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #00D0FF;
            }
    
            .etss-container-second-links {
                color: rgba(0, 0, 0, 1);
                margin-top: 20px;
    
                &-item {
                    text-decoration: underline;
                    cursor: pointer;
                    display: block;
                    margin-bottom: 5px;
                    font-size: 18px;
                }
                &-item:hover {
                    font-weight: 600;
                }
            }
        }
    
        &-img {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            text-align: center;
            img {
                width: 1175px;
            
            }
        }
    }
}