.strategy-card-text {
    color: black;
}
.strategy-card:hover .strategy-card-text {
    color: white;
}

.strategy-card-text {
    transition: color .5s;
}
.strategy-card {
    transition: background-color .5s;
}
.strategy-card-button {
    transition: all .5s;
}

@media screen and (min-width: 1281px) {
    .ts-container {
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 5% 0 5%;
            width: 750px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 70px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 11%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;

                height: 280px;
                overflow: hidden;
                padding: 30px;
                border-radius: 20px;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 60px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 90%;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }
            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}





// =============   < 1280   ==============



@media screen and (max-width: 1281px) and (min-width: 1025px) {
    .ts-container {
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 5% 0 5%;
            width: 750px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 70px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 10%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                height: 280px;
                overflow: hidden;
                padding: 30px;
                border-radius: 20px;

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 60px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }

            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}






// =============   < 1024   ==============



@media screen and (max-width: 1025px) and (min-width: 769px) {
    .ts-container {
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 5% 0 5%;
            width: 750px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 70px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 2%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                height: 320px;
                overflow: hidden;
                padding: 30px;
                border-radius: 20px;

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 60px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }

            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}






// =============   < 768   ==============



@media screen and (max-width: 769px) and (min-width: 481px) {
    .ts-container {
        ::-webkit-scrollbar {
            height: 0px;
        }
          
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 5% 0 5%;
            width: 90%;
        }

        &-content {
            margin-top: 50px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;
            width: 90%;
            overflow: scroll;
            overflow-y: hidden;
            padding: 20px 5% 20px 5%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 350px;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                height: 270px;
                padding: 30px;
                border-radius: 20px;
                // background-color: rgba(217, 217, 217, 0.01);
                // box-shadow: inset 0px -82px 68px -64px rgba(10, 1, 74, 0.3), inset 0px 98px 100px -48px rgba(1, 242, 216, 0.1), inset 0px 4px 18px rgba(6, 0, 53, 0.2), inset 0px 1px 40px rgba(1, 242, 216, 0.1);

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 60px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }

            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}






// =============   < 480   ==============



@media screen and (max-width: 481px) and (min-width: 361px) {
    .ts-container {
        ::-webkit-scrollbar {
            height: 0px;
        }
          
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 3% 0 3%;
            width: 90%;

            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            margin-top: 30px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            padding: 20px 3% 20px 3%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 230px;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                height: 270px;
                padding: 30px;
                border-radius: 20px;
                // background-color: rgba(217, 217, 217, 0.01);
                // box-shadow: inset 0px -82px 68px -64px rgba(10, 1, 74, 0.3), inset 0px 98px 100px -48px rgba(1, 242, 216, 0.1), inset 0px 4px 18px rgba(6, 0, 53, 0.2), inset 0px 1px 40px rgba(1, 242, 216, 0.1);

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 50px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    font-size: 24px;
                    line-height: 36px;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }

            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}




// =============   < 361   ==============



@media screen and (max-width: 361px) and (min-width: 321px) {
    .ts-container {
        ::-webkit-scrollbar {
            height: 0px;
        }
          
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 3% 0 3%;
            width: 90%;

            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            margin-top: 30px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            padding: 20px 3% 20px 3%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 200px;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                height: 270px;
                padding: 30px;
                border-radius: 20px;
                // background-color: rgba(217, 217, 217, 0.01);
                // box-shadow: inset 0px -82px 68px -64px rgba(10, 1, 74, 0.3), inset 0px 98px 100px -48px rgba(1, 242, 216, 0.1), inset 0px 4px 18px rgba(6, 0, 53, 0.2), inset 0px 1px 40px rgba(1, 242, 216, 0.1);

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 50px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    font-size: 24px;
                    line-height: 36px;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }

            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}






// =============   < 321   ==============



@media screen and (max-width: 321px) and (min-width: 0px) {
    .ts-container {
        ::-webkit-scrollbar {
            height: 0px;
        }
          
        width: 100%;
        margin-top: 150px;
        z-index: 4;
        position: relative;

        .header {
            padding: 0 3% 0 3%;
            width: 90%;

            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            margin-top: 30px;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            padding: 20px 3% 20px 3%;
            
            .strategy-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 160px;

                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);

                height: 270px;
                padding: 30px;
                border-radius: 20px;
                // background-color: rgba(217, 217, 217, 0.01);
                // box-shadow: inset 0px -82px 68px -64px rgba(10, 1, 74, 0.3), inset 0px 98px 100px -48px rgba(1, 242, 216, 0.1), inset 0px 4px 18px rgba(6, 0, 53, 0.2), inset 0px 1px 40px rgba(1, 242, 216, 0.1);

                &-icon {
                    z-index: 9;
                    position: relative;

                    #image-white {
                        display: none;
                    }

                    img {
                        height: 50px;
                    }
                }

                &-text {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    font-size: 24px;
                    line-height: 36px;
                }

                &-button {
                    position: absolute;
                    bottom: 30px;
                    z-index: 9;
                    margin-top: 60px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 150px;
                    height: 55px;
                    color: white;
                    background: #3F778A;
                    border-radius: 100px;
                    box-shadow: none;
                }
            }

            .strategy-card:hover .strategy-card-button {
                background-color: white;
                color: black;
                box-shadow: 0px 4px 6px #2F5C6B;
            }
            .strategy-card:hover {
                background: #3F778A;
                color: white;
                #image-blue {
                    display: none;
                }
                #image-white {
                    display: flex;
                }
            }
            .strategy-card:hover .strategy-card-text {
                margin-top: 24px;
            }
        }
    }
}