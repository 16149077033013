.gs-container {
    background-color: rgba(63, 119, 138, 1);
    color: white
}

@media screen and (min-width: 1701px) {
    .gs-container {
        width: 100%;
        display: flex;
        padding: 90px 0;

        .stats-block {
            width: 60%;
            align-items: center;

            display: grid;
            grid-template: repeat(1, 1fr) / repeat(4, 1fr);
            gap: 10%;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            padding: 0 5% 0 0;
            width: 40%;
            display: flex;
            align-items: center;

            &-text {
                left: 0;
                width: 230px;

                &-lang {
                    width: 250px;
                }
            }
        }

        &-button {
            position: relative;
            cursor: pointer;
            margin-right: 0%;
            z-index: 10;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            height: 25px;
            width: 110px;

            margin-left: auto;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}






// =============   < 1700   ==============




@media screen and (max-width: 1701px) and (min-width: 1501px) {
    .gs-container {
        width: 100%;
        display: flex;
        padding: 90px 0;

        .stats-block {
            width: 65%;
            align-items: center;

            display: grid;
            grid-template: repeat(1, 1fr) / repeat(4, 1fr);
            gap: 7%;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            padding: 0 5% 0 0;
            width: 35%;
            display: flex;
            align-items: center;

            &-text {
                width: 230px;
            }
        }

        &-button {
            position: relative;
            cursor: pointer;
            margin-right: 0%;
            z-index: 10;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            margin-left: auto;

            height: 25px;
            width: 110px;

            margin-left: auto;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 1500   ==============




@media screen and (max-width: 1501px) and (min-width: 1441px) {
    .gs-container {
        width: 100%;
        display: flex;
        padding: 90px 0;

        .stats-block {
            width: 65%;
            align-items: center;

            display: grid;
            grid-template: repeat(1, 1fr) / repeat(4, 1fr);
            gap: 5%;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            padding: 0 5% 0 0;
            width: 35%;
            display: flex;
            align-items: center;

            &-text {
                width: 230px;
            }
        }

        &-button {
            position: relative;
            cursor: pointer;
            margin-right: 0%;
            z-index: 10;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            margin-left: auto;

            height: 25px;
            width: 110px;

            margin-left: auto;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 1440   ==============




@media screen and (max-width: 1441px) and (min-width: 1025px) {
    .gs-container {
        width: 100%;
        padding: 90px 0;
        height: 200px;

        .stats-block {
            width: 90%;
            align-items: center;
            position: relative;

            display: grid;
            grid-template: repeat(1, 1fr) / repeat(4, 1fr);
            gap: 14%;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 5% 0 5%;
            margin-top: 70px;
            width: 90%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 40px auto 0 auto;
            cursor: pointer;
            z-index: 10;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
            cursor: pointer;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 1024   ==============




@media screen and (max-width: 1025px) and (min-width: 861px) {
    .gs-container {
        width: 100%;
        padding: 70px 0;
        height: 200px;

        .stats-block {
            width: 90%;
            align-items: center;
            position: relative;

            padding: 0 5% 0 5%;
            display: grid;
            grid-template: repeat(1, 1fr) / repeat(4, 1fr);
            gap: 9%;
            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 5% 0 5%;
            margin-top: 70px;
            width: 90%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 40px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 860   ==============




@media screen and (max-width: 861px) and (min-width: 769px) {
    .gs-container {
        width: 100%;
        padding: 80px 0;
        height: 200px;

        .stats-block {
            width: 90%;
            align-items: center;
            position: relative;

            display: grid;
            grid-template: repeat(1, 1fr) / repeat(4, 0fr);
            gap: 4%;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 5% 0 5%;
            margin-top: 70px;
            width: 90%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 40px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 768   ==============




@media screen and (max-width: 769px) and (min-width: 601px) {
    .gs-container {
        width: 100%;
        padding: 70px 0;
        height: 270px;

        .stats-block {
            width: 90%;
            align-items: center;
            position: relative;

            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            gap: 30px 170px;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 5% 0 5%;
            margin-top: 70px;
            width: 90%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 40px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}






// =============   < 600   ==============




@media screen and (max-width: 601px) and (min-width: 481px) {
    .gs-container {
        width: 100%;
        padding: 80px 0;
        height: 270px;

        .stats-block {
            width: 90%;
            align-items: center;
            position: relative;

            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            gap: 30px 100px;
            padding: 0 5% 0 5%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 64px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 5% 0 5%;
            margin-top: 70px;
            width: 90%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 40px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}






// =============   < 480   ==============




@media screen and (max-width: 481px) and (min-width: 421px) {
    .gs-container {
        width: 100%;
        padding: 70px 0;
        height: 270px;

        .stats-block {
            width: 94%;
            align-items: center;
            position: relative;
            z-index: 10;

            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            gap: 30px 100px;
            padding: 0 3% 0 3%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 40px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 3% 0 3%;
            margin-top: 50px;
            width: 94%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 30px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 420   ==============




@media screen and (max-width: 421px) and (min-width: 371px) {
    .gs-container {
        width: 100%;
        padding: 70px 0;
        height: 250px;

        .stats-block {
            width: 94%;
            align-items: center;
            position: relative;

            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 0fr);
            gap: 30px 40px;
            padding: 0 3% 0 3%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 40px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 3% 0 3%;
            margin-top: 50px;
            width: 94%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 30px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}





// =============   < 370   ==============




@media screen and (max-width: 371px) and (min-width: 0px) {
    .gs-container {
        width: 100%;
        padding: 70px 0;
        height: 370px;

        .stats-block {
            width: 94%;
            align-items: center;
            position: relative;

            display: grid;
            grid-template: repeat(4, 1fr) / repeat(1, 0fr);
            gap: 20px 20px;
            padding: 0 3% 0 3%;

            .gs-container-stats-item {
                display: flex;

                .main-text {
                    line-height: 40px;
                    font-size: 40px;
                    font-weight: 400;
                }
                .second-text {
                    font-weight: 400;
                    // max-width: 60px;
                    width: 74px;
                    margin: auto 0 auto 10px;
                }
                .second-text-ru {
                    font-weight: 400;
                    margin-left: 10px;
                    width: 100px;
                }
                .desc-text {
                    max-width: 160px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .get-started-block {
            position: absolute;
            margin-left: auto;
            
            padding: 0 3% 0 3%;
            margin-top: 50px;
            width: 94%;
            align-items: center;

            &-text {
                text-align: center;
            }
        }

        &-button {
            margin: 30px auto 0 auto;
            cursor: pointer;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: black;

            position: relative;
        
            height: 25px;
            width: 110px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 14px 30px;
            gap: 10px;

            background: white;
            border-radius: 100px;
            box-shadow: 0px 4px 6px #2F5C6B;
        }
    }
}