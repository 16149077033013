.desc {
    color: black!important;
}

@media screen and (min-width: 1541px) {
    .ss-planet-container {
        width: 80%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 10% 0 10%;
    
        &-content {
            position: absolute;
    
            .header {
                &-en {
                    margin-top: 50px;
                    width: 840px;
                    font-size: 86px;
                    line-height: 103px;
                }
                &-ru {
                    margin-top: 50px;
                    width: 1100px;
                    font-size: 86px;
                    line-height: 103px;
                }
            }
        
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            width: 700px;
            position: absolute;
            right: 5%;
            top: 160px;
        }
    }    
}




@media screen and (min-width: 1281px) and (max-width: 1540px) {
    .ss-planet-container {
        width: 80%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 10% 0 10%;
        z-index: -1;
    
        &-content {
            position: absolute;
    
            .header {
                &-en {
                    margin-top: 50px;
                    width: 840px;
                    font-size: 86px;
                    line-height: 103px;

                }
                &-ru {
                    margin-top: 50px;
                    width: 1100px;
                    font-size: 86px;
                    line-height: 103px;
                }
            }
        
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: -1;
            width: 500px;
            position: absolute;
            right: 0%;
            top: 250px;
        }
    }    
}




@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            z-index: 9;
            
            .header {
                &-en {
                    margin-top: 50px;
                    width: 840px;
                    font-size: 86px;
                    line-height: 103px;
                }
                &-ru {
                    margin-top: 50px;
                    width: 100%;
                    font-size: 86px;
                    line-height: 103px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 450px;
            position: absolute;
            right: 0;
            top: 0px;
            margin-top: 260px;
        }
    }    
}







@media screen and (min-width: 1001px) and (max-width: 1024px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            z-index: 9;
    
            .header {
                &-en {
                    width: 840px;
                    font-size: 86px;
                    line-height: 103px;
                }
                &-ru {
                    width: 900px;
                    font-size: 86px;
                    line-height: 103px;
                }
            }

            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 600px;
            position: absolute;
            right: 0;
            top: 250px;
        }
    }    
}





@media screen and (min-width: 801px) and (max-width: 1000px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            z-index: 9;

            .header {
                &-en {
                    width: 760px;
                    font-size: 86px;
                    line-height: 103px;
                }
                &-ru {
                    width: 760px;
                    font-size: 86px;
                    line-height: 103px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 600px;
            position: absolute;
            right: 0;
            top: 250px;
            // margin-top: -50px;
        }
    }    
}





@media screen and (min-width: 769px) and (max-width: 800px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            width: 650px;
            z-index: 9;
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 550px;
            position: absolute;
            right: 0;
            top: 250px;
        }
    }    
}






@media screen and (min-width: 691px) and (max-width: 768px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            width: 650px;
            z-index: 9;

            .header {
                &-en {
                    font-size: 50px;
                    line-height: 60px;
                }
                &-ru {
                    font-size: 50px;
                    line-height: 60px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 500px;
            position: absolute;
            right: 0;
            top: 270px;
        }
    }    
}






@media screen and (min-width: 601px) and (max-width: 690px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            width: 500px;
            z-index: 9;

            .header {
                &-en {
                    font-size: 50px;
                    line-height: 60px;
                }
                &-ru {
                    font-size: 50px;
                    line-height: 60px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 400px;
            position: absolute;
            right: 0;
            top: 160px;
        }
    }    
}




@media screen and (min-width: 481px) and (max-width: 600px) {
    .ss-planet-container {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 5% 0 5%;
    
        &-content {
            position: absolute;
            width: 440px;
            z-index: 9;

            .header {
                &-en {
                    font-size: 50px;
                    line-height: 60px;
                }
                &-ru {
                    font-size: 50px;
                    line-height: 60px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 24px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 400px;
            position: absolute;
            right: 0;
            top: 200px;
        }
    }    
}






@media screen and (min-width: 381px) and (max-width: 480px) {
    .ss-planet-container {
        width: 94%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 3% 0 3%;
    
        &-content {
            position: absolute;
            width: 370px;
            z-index: 9;

            .header {
                &-en {
                    font-size: 48px;
                    line-height: 60px;
                    margin-top: 30px;
                }
                &-ru {
                    font-size: 40px;
                    line-height: 50px;
                    margin-top: 30px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 20px;
                font-size: 20px;
            }
        }
        &-planet {
            z-index: 8;
            width: 90%;
            margin-left: 7%;
            position: absolute;
            top: 410px;
        }
    }    
}




@media screen and (min-width: 326px) and (max-width: 380px) {
    .ss-planet-container {
        width: 94%;
        height: 100%;
        position: relative;
        z-index: -1;
        padding: 0 3% 0 3%;
    
        &-content {
            position: absolute;
            width: 310px;
            z-index: 9;

            .header {
                &-en {
                    font-size: 48px;
                    line-height: 60px;
                    margin-top: 40px;
                }
                &-ru {
                    font-size: 40px;
                    line-height: 50px;
                    margin-top: 30px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 20px;
            }
        }
    
        &-planet {
            z-index: 8;
            width: 70%;
            margin-left: 27%;
            position: absolute;
            top: 410px;
        }
    }    
}






@media screen and (min-width: 0px) and (max-width: 325px) {
    .ss-planet-container {
        width: 94%;
        height: 100%;
        position: relative;
        z-index: 9;
        padding: 0 3% 0 3%;
    
        &-content {
            position: absolute;
            width: 100%;
            z-index: 9;

            .header {
                &-en {
                    font-size: 42px;
                    line-height: 50px;
                }
                &-ru {
                    font-size: 36px;
                    line-height: 45px;
                }
            }
    
            .desc {
                color: rgba(81, 81, 81, 0.7);
                margin-top: 30px;
                font-size: 20px;
            }
        }
    
        &-planet {
            // overflow: hidden;
            z-index: 8;
            width: 100%;
            position: absolute;
            // right: 0;
            top: 200px;
        }
    }    
}