.sun-container {
    background-color: transparent;
    color: #3F778A;
}

@media screen and (min-width: 1441px) {
    .sun-container {
        width: 90%;
        padding: 50px 5%;
        position: relative;
        display: flex;
    
        &-content {
            width: calc(100% - 180px - 70px); // - btn width - margin
    
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(4, 0fr);
            gap: 0 10%;
    
            &-item {
                display: flex;

                .main-text {
                    font-size: 64px;
                }
    
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: auto 0 auto auto;
            
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}





@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .sun-container {
        width: 90%;
        padding: 50px 5%;
        position: relative;
        display: flex;
    
        &-content {
            width: calc(100% - 180px - 20px); // - btn width - margin
    
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(4, 0fr);
            gap: 0 6%;
    
            &-item {
                display: flex;

                .main-text {
                    font-size: 64px;
                }
    
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: auto 0 auto auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}







@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .sun-container {
        width: 90%;
        padding: 50px 5%;
        position: relative;
        // display: flex;
    
        &-content {
            width: 100%;
            justify-content: center;
            display: flex;
            // grid-template: repeat(1, 0fr) / repeat(4, 0fr);
            gap: 0 70px;
    
            &-item {
                display: flex;
                .main-text {
                    font-size: 64px;
                }
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: 30px auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}






@media screen and (min-width: 1001px) and (max-width: 1024px) {
    .sun-container {
        width: 90%;
        padding: 50px 5%;
        position: relative;
    
        &-content {
            margin: 0 auto;
            width: 100%;
            // display: grid;
            // grid-template: repeat(2, 0fr) / repeat(2, 1fr);
            // gap: 0 100px;
            display: flex;
            justify-content: space-between;
    
            &-item {
                display: flex;
                .main-text {
                    font-size: 64px;
                }
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: 30px auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}



@media screen and (min-width: 601px) and (max-width: 1000px) {
    .sun-container {
        width: 90%;
        padding: 50px 5%;
        position: relative;
    
        &-content {
            margin: 0 auto;
            width: 100%;
            display: grid;
            grid-template: repeat(2, 0fr) / repeat(2, 1fr);
            // gap: 0 100px;
    
            &-item {
                display: flex;
                .main-text {
                    font-size: 64px;
                }
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: 30px auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}



@media screen and (min-width: 481px) and (max-width: 600px) {
    .sun-container {
        width: 90%;
        padding: 50px 5%;
        position: relative;
    
        &-content {
            margin: 0 auto;
            width: 100%;
    
            display: grid;
            grid-template: repeat(4, 1fr) / repeat(1, 1fr);
    
            &-item {
                display: flex;
                margin-bottom: 10px;
                .main-text {
                    font-size: 64px;
                }
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: 30px auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}





@media screen and (min-width: 321px) and (max-width: 480px) {
    .sun-container {
        width: 94%;
        padding: 50px 3%;
        position: relative;
    
        &-content {
            margin: 0 auto;
            width: 100%;
    
            display: grid;
            grid-template: repeat(4, 1fr) / repeat(1, 1fr);
    
            &-item {
                display: flex;
                margin-bottom: 10px;

                .main-text {
                    font-size: 48px;
                    line-height: 48px;
                }
    
                .second-text {
                    margin: auto 0 auto 10px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: 30px auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}






@media screen and (min-width: 0px) and (max-width: 320px) {
    .sun-container {
        width: 94%;
        padding: 50px 3%;
        position: relative;
    
        &-content {
            margin: 0 auto;
            width: 100%;
    
            display: grid;
            grid-template: repeat(4, 0fr) / repeat(1, 1fr);
    
            &-item {
                // display: flex;
                margin-bottom: 10px;

                .main-text {
                    font-size: 40px;
                    line-height: 46px;
                }
    
                .second-text {
                    margin: auto 0 auto 0px;
                    color: #515151;
                    font-weight: 600;
                }
            }
        }
    
        &-btn {
            right: 0;
            margin: 30px auto;
    
            position: relative;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            width: 180px;
            height: 55px;
            
            color: white;
            background: #3F778A;
            border-radius: 100px;
            cursor: pointer;
        }
    }
}




