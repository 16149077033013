.ap-card-content-header {
    color: #3F778A;
    transition: color .5s;
}
.ap-card-content-text-1, .ap-card-content-text-2 {
    color: #515151;
    transition: color .5s;
}
.ap-card-content-text-3 {
    color: rgba(0, 0, 0, 1);
    transition: color .5s;
}
.ap-card {
    transition: background-color .5s;
}
.ap-card-btn {
    transition: all .5s;
}

.ap-card:hover {
    #img-dark {
        display: none;
    }
    #img-white {
        display: block;
    }
    .ap-card-content {
        margin-top: 64px;
    }
    .ap-card-content-header, .ap-card-content-text-3 {
        color: rgba(255, 255, 255, 1);
    }
    .ap-card-content-text-3 {
        font-weight: 100;
    }
    .ap-card-content-text-1, .ap-card-content-text-2 {
        color: rgba(255, 255, 255, 0.7);
    }
    background-color: #3F778A;
}
.ap-card:hover .ap-card-btn {
    background-color: white;
    color: black;
}

@media (max-width: 480px) {
    .ap-card:hover {
        .ap-card-content {
            margin-top: 24px;
        }
        .ap-card-content-text-3 {
            font-weight: 600;
        }
    }
}
@media (max-width: 360px) {
    .ap-card:hover {
        .ap-card-content {
            margin-top: 14px;
        }
    }
}

@media screen and (min-width: 1481px) {
    .ap-container {
        width: 100%;
        margin: 150px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5%;
            margin-top: 40px;

            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 2%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                overflow: hidden;
                padding: 30px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    img {
                        height: 60px;
                    }
                    #img-white {
                        display: none;
                    }
                }

                &-content {
                    margin-top: 60px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    &-text {
                        &-1 {
                            margin-top: 30px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 20px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    min-height: 460px;
                    height: 100%;
                }
                &-ru, &-pl, &-cz {
                    min-height: 500px;
                    height: 100%;
                }
                &-es, &-ro {
                    min-height: 470px;
                    height: 100%;
                }
                &-it {
                    min-height: 470px;
                    height: 100%;
                }
                &-de {
                    min-height: 470px;
                    height: 100%;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}









@media screen and (min-width: 1281px) and (max-width: 1480px) {
    .ap-container {
        width: 100%;
        margin: 150px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5%;
            margin-top: 40px;

            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 2%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;

                overflow: hidden;
                padding: 30px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    img {
                        height: 60px;
                    }
                    #img-white {
                        display: none;
                    }
                }

                &-content {
                    margin-top: 60px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    &-text {
                        &-1 {
                            margin-top: 30px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 20px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 520px;
                }
                &-ru, &-pl, &-cz {
                    height: 600px;
                }
                &-es, &-ro {
                    min-height: 520px;
                    height: 100%;
                }
                &-it {
                    min-height: 500px;
                    height: 100%;
                }
                &-de {
                    min-height: 500px;
                    height: 100%;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}








@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .ap-container {
        width: 100%;
        margin: 150px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5%;
            margin-top: 40px;

            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 2%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;

                overflow: hidden;
                padding: 30px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 60px;
                    }
                }

                &-content {
                    margin-top: 60px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    &-text {
                        &-1 {
                            margin-top: 30px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 20px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 560px;
                }
                &-ru, &-pl, &-cz {
                    height: 760px;
                }
                &-es, &-ro {
                    height: 610px;
                }
                &-it {
                    height: 650px;
                }
                &-de {
                    height: 660px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}








@media screen and (min-width: 641px) and (max-width: 1024px) {
    .ap-container {
        ::-webkit-scrollbar {
            height: 0px;
        }

        width: 100%;
        margin: 150px 0 130px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 5%;
            width: 90%;
        }

        &-content {
            padding: 20px 5%;
            margin-top: 20px;
            width: 90%;
            overflow: scroll;
            overflow-y: hidden;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 450px;

                overflow: hidden;
                padding: 30px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 60px;
                    }
                }

                &-content {
                    margin-top: 60px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    &-text {
                        &-1 {
                            margin-top: 30px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 20px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: inline-flex;
                    padding: 18px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 460px;
                }
                &-ru, &-pl, &-cz {
                    height: 540px;
                }
                &-es, &-ro {
                    height: 530px;
                }
                &-it {
                    height: 480px;
                }
                &-de {
                    height: 520px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}






@media screen and (min-width: 481px) and (max-width: 640px) {
    .ap-container {
        ::-webkit-scrollbar {
            height: 0px;
        }

        width: 100%;
        margin: 100px 0 80px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 5%;
            width: 90%;
        }

        &-content {
            padding: 20px 5%;
            margin-top: 20px;
            width: 90%;
            overflow: scroll;
            overflow-y: hidden;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 330px;

                overflow: hidden;
                padding: 30px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 60px;
                    }
                }

                &-content {
                    margin-top: 60px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    &-text {
                        &-1 {
                            margin-top: 30px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 20px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: inline-flex;
                    padding: 16px 30px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 500px;
                }
                &-ru, &-pl, &-cz {
                    height: 600px;
                }
                &-es, &-ro {
                    height: 550px;
                }
                &-it {
                    height: 550px;
                }
                &-de {
                    height: 580px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}






@media screen and (min-width: 361px) and (max-width: 480px) {
    .ap-container {
        ::-webkit-scrollbar {
            height: 0px;
        }

        width: 100%;
        margin: 130px 0 110px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 20px 3%;
            margin-top: 10px;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 270px;

                overflow: hidden;
                padding: 20px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 40px;
                    }
                }

                &-content {
                    margin-top: 20px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    .ap-card-content-header {
                        font-size: 20px;
                    }

                    &-text {
                        &-1 {
                            margin-top: 15px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 20px;
                            font-size: 18px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: inline-flex;
                    padding: 16px 25px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 500px;
                }
                &-ru, &-pl, &-cz {
                    height: 610px;
                }
                &-es, &-ro {
                    height: 530px;
                }
                &-it {
                    height: 530px;
                }
                &-de {
                    height: 560px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}





@media screen and (min-width: 321px) and (max-width: 360px) {
    .ap-container {
        ::-webkit-scrollbar {
            height: 0px;
        }

        width: 100%;
        margin: 130px 0 110px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 20px 3%;
            margin-top: 10px;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 240px;

                overflow: hidden;
                padding: 20px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 30px;
                    }
                }

                &-content {
                    margin-top: 10px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    .ap-card-content-header {
                        font-size: 20px;
                    }

                    &-text {
                        &-1 {
                            margin-top: 10px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 15px;
                            font-size: 18px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 150px;
                    height: 50px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 460px;
                }
                &-ru, &-pl, &-cz {
                    height: 670px;
                }
                &-es, &-ro {
                    height: 540px;
                }
                &-it {
                    height: 520px;
                }
                &-de {
                    height: 600px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}





@media screen and (min-width: 271px) and (max-width: 320px) {
    .ap-container {
        ::-webkit-scrollbar {
            height: 0px;
        }

        width: 100%;
        margin: 130px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3%;
            margin-top: 30px;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 190px;

                overflow: hidden;
                padding: 20px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 30px;
                    }
                }

                &-content {
                    margin-top: 10px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    .ap-card-content-header {
                        font-size: 20px;
                    }

                    &-text {
                        &-1 {
                            margin-top: 10px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 15px;
                            font-size: 18px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 150px;
                    height: 50px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 540px;
                }
                &-ru, &-pl, &-cz {
                    height: 750px;
                }
                &-es, &-ro {
                    height: 600px;
                }
                &-it {
                    height: 600px;
                }
                &-de {
                    height: 600px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}





@media screen and (min-width: 0px) and (max-width: 270px) {
    .ap-container {
        ::-webkit-scrollbar {
            height: 0px;
        }

        width: 100%;
        margin: 130px 0;
        position: relative;
        z-index: 4;

        .ap-container-header {
            padding: 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3%;
            margin-top: 30px;
            width: 94%;
            overflow: scroll;
            overflow-y: hidden;
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(3, 1fr);
            gap: 0px 5%;

            .ap-card {
                position: relative;
                z-index: 9;
                cursor: pointer;
                width: 150px;

                overflow: hidden;
                padding: 20px;
                border: 1px solid #F2F2F2;
                box-shadow: 0px 0px 30px rgba(0, 95, 203, 0.1);
                border-radius: 30px;

                &-icon {
                    z-index: 9;
                    position: relative;
                    #img-white {
                        display: none;
                    }
                    img {
                        height: 30px;
                    }
                }

                &-content {
                    margin-top: 10px;
                    position: relative;
                    z-index: 9;
                    width: 100%;

                    .ap-card-content-header {
                        font-size: 20px;
                    }

                    &-text {
                        &-1 {
                            margin-top: 10px;
                        }
                        &-2 {
                            margin-top: 10px;
                        }
                        &-3 {
                            margin-top: 15px;
                            font-size: 18px;
                        }
                    }
                }

                &-btn {
                    margin-top: 60px;
                    bottom: 30px;
                    position: absolute;
                    z-index: 9;
                    color: white;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 150px;
                    height: 50px;
                    background: #3F778A;;
                    border-radius: 100px;
                }
            }

            .height {
                &-en {
                    height: 650px;
                }
                &-ru, &-pl, &-cz {
                    height: 850px;
                }
                &-es, &-ro {
                    height: 700px;
                }
                &-it {
                    height: 700px;
                }
                &-de {
                    height: 700px;
                }
            }
        }
        .ap-card:hover {
            #img-dark {
                display: none;
            }
            #img-white {
                display: block;
            }
        }
    }
}
