.banner__wrap {
  padding: 60px 0;
  display: flex;
  min-height: 355px;
  height: 100%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.banner__title {
  font-family: "Bebas";
  font-size: 64px;
  font-weight: 400;
  margin-top: 0;
}

.banner__text {
  max-width: 560px;
  width: 100%;
  font-family: 'Inter';
  font-size: 16px;
  color: rgba(81, 81, 81, 1);
}

.banner_text {
  max-width: 610px;
  width: 100%;
  font-family: 'Inter';
  font-size: 16px;
  color: rgba(81, 81, 81, 1);
}

.banner__link {
  position: relative;
  display: inline-flex;
  font-family: "Inter";
  font-size: 16px;
  color: white;
  padding: 16px 30px;
  background: rgba(63, 119, 138, 1);
  border-radius: 100px;
  text-decoration: none;
  margin-top: 15px;
  cursor: pointer;
}

.banner-component-img__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  .base-bg {
    max-width: 100%;
    height: auto;
  }
  .icon {
    position: absolute;
    max-width: 100%;
    height: auto;
    &.icon {
      &-1 {
        left: 35.5%;
        top: 60px;
      }
      &-2 {
        left: 58.5%;
        top: 65px;
      }
      &-3 {
        right: 47.4%;
        top: 250px;
      }
      &-4 {
        right: 47.5%;
        top: 120px;
      }
      &-5 {
        right: 36.2%;
        top: 185px;
      }
      &-6 {
        right: 59%;
        top: 185px;
      }
    }
  }
}

.d-none {
  display: none;
}

@media (min-width: 768px) {
  .d-md-block {
    display: block;
  }
}

@media (max-width: 480px) {
  .banner__title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media (max-width: 1600px) {
  .banner-component-img__wrap {
    .icon {
      position: absolute;
      max-width: 100%;
      height: auto;
      &.icon {
        &-1 {
          left: 31%;
          top: 60px;
        }
        &-2 {
          left: 62%;
          top: 65px;
        }
        &-3 {
          right: 46.5%;
          top: 250px;
        }
        &-4 {
          right: 46.5%;
          top: 120px;
        }
        &-5 {
          right: 32%;
          top: 185px;
        }
        &-6 {
          right: 63%;
          top: 185px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .banner__wrap {
    min-height: 300px;
  }
  .banner-component-img__wrap {
    display: none;
  }
}