.form__wrap {
  max-width: 970px;
  width: 100%;
  margin: 0 auto 0px;
  padding: 50px 0;
}
input {
  outline: none;
}

.position-relative {
  position: relative;
}

.bg-gradient-blue {
  background: rgb(238,245,251);
  background: linear-gradient(90deg, rgba(238,245,251,1) 35%, rgba(255,255,255,1) 100%);
}

.form__title {
  position: relative;
  z-index: 2;
  font-family: "Bebas";
  font-size: 64px;
  font-weight: 400;
  text-align: center;
}

.form__text {
  position: relative;
  z-index: 2;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 10px 0;
  &.grey {
    font-size: 20px;
    color: #515151;
  }
  &.black {
    font-size: 20px;
    font-weight: 600;
    color: black;
  }
  &.white {
    font-size: 16px;
    color: #fff;
  }
}
.form-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../../../assets/company-support/form-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  bottom: 0;
  right: 0;
  z-index: 1;
}

form {
  &.row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .form__group {
    margin: 20px 40px 15px 20px;
    &.h-100 {
      height: 100%;
      textarea {
        height: calc(100% - 75px);
      }
    }
    .form-input {
      position: relative;
      width: 100%;
      background-color: white;
      border: none;
      outline: none;
      border-radius: 30px;
      padding: 20px 15px;
      z-index: 9;
      &::placeholder {
        font-family: 'Inter';
        color: #515151;
      }
    }
    .submit__btn {
      position: relative;
      display: inline-flex;
      font-family: "Inter";
      font-size: 16px;
      color: white;
      padding: 16px 35px;
      background: #3F778A;
      border-radius: 100px;
      text-decoration: none;
      margin-top: 15px;
      cursor: pointer;
      z-index: 9;
      border: none;
    }
  }
}

@media (max-width: 480px) {
  .form__title {
    font-size: 42px;
    line-height: 54px;
  }

  form {
    &.row {
      position: relative;
      display: block;
      flex-wrap: wrap;
      .col-6 {
        flex: 0 0 50%;
        max-width: 100%;
      }
    }
    .form__group {
      // margin: 20 40px 15 10px;
      margin: 20px 40px 15px 10px;
    }
  }
}
