.value__wrap {
  margin-top: 50px;
  .row {
    display: flex;
    flex-wrap: wrap;
    &.position-relative {
      position: relative;
    }
  }
}

.d-none {
  display: none;
}

.align-items-center {
  align-items: center;
}

.settings__wrap {
  width: 560px;
  position: relative;
}

.setting {
  &-1 {
    width: 245px;
    position: absolute;
  }
  &-2 {
    position: absolute;
    left: 10%;
    top: 56.5%;
    width: 160px;
  }
  &-3 {
    position: absolute;
    // left: 0%;
    right: 88.5%;
    top: 58.5%;
    width: 110px;
  }
  &-4 {
    position: absolute;
    right: 1%;
    top: 23.5%;
    width: 180px;
  }
  &-5 {
    position: absolute;
    right: -45px;
    top: 59%;
    width: 155px;
  }
  &-6 {
    position: absolute;
    right: -30px;
    bottom: 40%;
    width: 105px;
  }
}

.icon {
  &-1 {
    position: absolute;
  }
  &-2 {
    position: absolute;
    left: 18.2%;
    top: 64%;
  }
  &-3 {
    position: absolute;
    right: 96.9%;
    top: 63.7%;
  }
  &-4 {
    position: absolute;
    right: 10%;
    top: 30.5%;
  }
  &-5 {
    position: absolute;
    right: -3px;
    top: 67%;
  }
  &-6 {
    position: absolute;
    right: 0px;
    bottom: 44.6%;
  }
}

.col-5 {
  max-width: 560px;
  width: 100%;
}

.values__title {
  font-family: "Bebas";
  font-size: 64px;
  font-weight: 400;
}

.values__text {
  font-family: 'Inter';
  font-size: 16px;
  max-width: 560px;
  width: 100%;
  color: #515151;
}

.values__link {
  position: relative;
  display: inline-flex;
  font-family: "Inter";
  font-size: 16px;
  color: white;
  padding: 16px 30px;
  background: #3F778A;
  border-radius: 100px;
  text-decoration: none;
  margin-top: 15px;
}


@media (min-width: 768px) {
  .d-md-block {
    display: block!important;
  }
}

@media (max-width: 480px) {
  .value__wrap {
    margin-top: 0;
  }
  .values__title {
    font-size: 36px;
  }
}
