.column-color-green {
    color: #00BD41;
}
.column-color-red {
    color: #F4002B;
}
.column-arrow {
    padding-left: 6px;
    padding-bottom: 3px;
    vertical-align: center;
    display: inline-block;
}

@media (min-width: 601px) {
    .sort-arrow-up::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 22px;
        height: 26px;
        background-image: url('../../../assets/sort_arrow_up.png');
        background-repeat: no-repeat;
        background-position: center;
    }
    .sort-arrow-default::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 14px;
        height: 25px;
        background-image: url('../../../assets/sort_arrow_default.png');
        background-repeat: no-repeat;
        background-position: center right;
    }
}
@media (max-width: 600px) {
    .sort-arrow-up {
        background-image: url('../../../assets/sort_arrow_up.png');
        background-repeat: no-repeat;
        background-position: center right;
    }
    .sort-arrow-default {
        background-image: url('../../../assets/sort_arrow_default.png');
        background-repeat: no-repeat;
        background-position: center right;
    }
}



.scroll-style {
    &::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar:horizontal {
		height: 3px;
	}
	&::-webkit-scrollbar-track {
		background-color: white;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: transparentize(grey, 0.3);
		// box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
	}
}

.table-container {
    padding: 0 5%;
    table  th:nth-child(1){
        -webkit-border-radius: 100px 0px 0px 100px; 
        -moz-border-radius: 100px 0px 0px 100px; 
        border-radius: 100px 0px 0px 100px; 
    }
    table  th:nth-last-child(1){
        -webkit-border-radius: 0px 100px 100px 0px; 
        -moz-border-radius: 0px 100px 100px 0px; 
        border-radius: 0px 100px 100px 0px;
        padding-right: 25px;
    }
    table td:nth-last-child(1) {
        padding-right: 25px;
    }

    table {
        font-family: 'Inter';
        width: 100%;
        border-spacing: 0 20px;
        color: black;

        thead {
            background-color: #3F778A;
            height: 50px;
            font-size: 20px;
            th {
                font-weight: normal;
            }
        }

        tbody {
            td {
                font-size: 16px;
            }
        }

        .t-col {
            &-1 {
                padding-left: 25px;
                width: 70px;
            }
            &-2 {
                padding-left: 120px;
                width: 350px;
            }
            &-5 {
                padding-left: 50px;
            }
        }
    }
}

.pagination {
    padding: 0 5%;
    display: flex;
    font-family: 'Inter';
    justify-content: right;

    &-text {
        margin: auto 20px auto 0;
        color: black;
    }
    &-arrow {
        margin-left: 20px;
    }
}

@media (max-width: 1281px) {
    table {
        .t-col-1 {
            padding-left: 25px!important;
            width: 70px!important;
        }
        .t-col-2 {
            padding-left: 70px!important;
            width: 300px!important;
        }
        .t-col-5 {
            padding-left: 0px!important;
        }
        .t-col-6 {
            width: 200px;
        }
    }
}

@media (max-width: 1025px) {
    table {
        .t-col-1 {
            padding-left: 25px!important;
            width: 70px!important;
        }
        .t-col-2 {
            padding-left: 30px!important;
            width: 250px!important;
        }
        .t-col-5 {
            padding-left: 0px!important;
        }
        .t-col-6 {
            width: 200px;
        }
    }
}


@media (max-width: 769px) {
    table {
        thead {
            font-size: 16px!important;
        }
        .t-col-2 {
            width: 200px!important;
        }
        .t-col-5 {
            width: 80px;
        }
        .t-col-6 {
            width: 90px;
        }
    }
}

@media (max-width: 600px) {
    .t-col-2, .t-col-3, .t-col-4, .t-col-5, .t-col-6 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .t-col-6 {
        margin-right: 10px;
    }
    .table-container {
        overflow-x: scroll!important;
        overflow-y: hidden;
        padding: 0 0;
    }
    table {
        margin: 0 5%;
        width: 700px!important;
    }
    .pagination {
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .pagination {
        padding: 0 3%;
    }
    table {
        margin: 0 3%;
    }
}