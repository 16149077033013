.about-company__container {
  .row {
    display: flex;
    flex-wrap: wrap;
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
      &:first-child, &:nth-child(3) {
        .about-company__wrap {
          padding-left: 0;
        }
      }
      &:last-child, &:nth-child(2) {
        .about-company__wrap {
          padding-right: 0;
        }
      }
      .about-company__wrap {
        padding: 20px 60px;
        &:first-child {
          padding-left: 0;
        }
        .about-company__title {
          font-family: Bebas;
          font-weight: 100;
          font-size: 64px;
        }
        .about-company__text {
          font-family: 'Inter';
          font-size: 16px;
          color: rgba(81, 81, 81, 1);
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .about-company__container {
    .row {
      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .about-company__wrap {
        padding: 0;
        .about-company__title {
          font-size: 48px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .about-company__wrap {
    padding: 30px 0!important;
  }
  .about-company__title {
    font-size: 36px!important;
    line-height: 46px!important;
  }
}