.container {
  padding: 0 15px;
  position: relative;
}

@media (min-width: 990px) {
  .container {
    padding: 0 100px;
  }
}
