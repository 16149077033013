.different-brokers__container {
  position: relative;
  padding-bottom: 100px;
  .different-brokers__title {
    font-family: 'Bebas';
    font-size: 64px;
    max-width: 680px;
    width: 100%;
    font-weight: 100;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    .different-brokers__text {
      font-family: 'Inter';
      &.white {
        font-size: 20px;
        line-height: 30px;
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
      }
      &.grey {
        color: #515151;
        font-size: 16px;
        line-height: 24px;
      }
      &.subtitle {
        color: #00D0FF;
        font-size: 24px;
        font-weight: 700;
      }
    }
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
      &.d-flex {
        display: flex;
      }
      &.justify-content-center {
        justify-content: center;
      }
      .abstract__img {
        position: absolute;
        top: 0px;
        right: -15px;
        width: 680px;
        height: 476px;
        background-image: url("../../../assets/wave.png");
        background-repeat: no-repeat;
        background-size: 570px;
        background-position: top right;
      }
      .img-fluid {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (min-width: 768px) {
  .different-brokers__container {
    .row {
      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
        .pr-70 {
          padding-right: 70px;
        }
        .abstract__img {
          position: absolute;
          top: -40px;
          right: -100px;
          width: 680px;
          height: 476px;
          background-image: url("../../../assets/wave.png");
          background-repeat: no-repeat;
          background-size: 570px;
          background-position: top right;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .different-brokers__title {
    font-size: 36px!important;
    line-height: 46px!important;
  }
}
