.steps-text-par-header {
    color: black;
}
.steps-text-par-desc {
    color: rgba(81, 81, 81, 1);
}
.tt2-container-footer-text {
    color: rgba(0, 0, 0, 1);
}

@media screen and (min-width: 1541px) {
    .tt2-container {
        margin: 150px 0;

        .tt2-container-header {
            padding: 0 5% 0 5%;
            width: 900px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;
                    &-par {
                        width: 450px;
                        height: 133px;
                        &-header {
                            font-weight: 700;
                        }
                        &-desc {
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                width: 50%;

                img {
                    position: absolute;
                }
                #bages {
                    z-index: 10;
                    width: 500px;
                    margin: 5% 20px 0 15%;
                }
                #chart {
                    z-index: 9;
                    width: 800px;
                    right: 0;
                }
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: -10px;

            &-button {
                margin-top: 40px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}






@media screen and (min-width: 1281px) and (max-width: 1540px) {
    .tt2-container {
        margin: 150px 0;

        .tt2-container-header {
            padding: 0 5% 0 5%;
            width: 900px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;
                    &-par {
                        width: 450px;
                        height: 133px;
                        &-header {
                            font-weight: 700;
                        }
                        &-desc {
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                width: 50%;

                img {
                    position: absolute;
                }
                #bages {
                    z-index: 10;
                    width: 500px;
                    right: 50px;
                    margin-top: 50px;
                }
                #chart {
                    z-index: 9;
                    margin-top: 50px;
                    width: 600px;
                    right: 0;
                }
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: -10px;

            &-button {
                margin-top: 40px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}







@media screen and (min-width: 1131px) and (max-width: 1280px) {
    .tt2-container {
        margin: 150px 0;

        .tt2-container-header {
            padding: 0 5% 0 5%;
            width: 900px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;
                    &-par {
                        width: 450px;
                        height: 133px;
                        &-header {
                            font-weight: 700;
                        }
                        &-desc {
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                width: 50%;

                img {
                    position: absolute;
                }
                #bages {
                    z-index: 10;
                    width: 400px;
                    right: 50px;
                    margin-top: 50px;
                }
                #chart {
                    z-index: 9;
                    margin-top: 50px;
                    width: 500px;
                    right: 0;
                }
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: -10px;

            &-button {
                margin-top: 40px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}







@media screen and (min-width: 601px) and (max-width: 1130px) {
    .tt2-container {
        margin: 100px 0 100px 0;

        .tt2-container-header {
            padding: 0 5% 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;
                    &-par {
                        width: 450px;
                        height: 133px;
                        &-header {
                            font-weight: 700;
                        }
                        &-desc {
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: -10px;

            &-button {
                margin-top: 40px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}





@media screen and (min-width: 480px) and (max-width: 600px) {
    .tt2-container {
        margin: 100px 0 100px 0;

        .tt2-container-header {
            padding: 0 5% 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;
                    &-par {
                        width: 330px;
                        height: 133px;
                        &-header {
                            font-weight: 700;
                        }
                        &-desc {
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: -10px;

            &-button {
                margin-top: 40px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}




@media screen and (min-width: 376px) and (max-width: 481px) {
    .tt2-container {
        margin: 100px 0 100px 0;

        .tt2-container-header {
            padding: 0 3% 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3% 0 3%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    img {
                        height: 250px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 20px;
                    &-par {
                        width: 250px;
                        height: 111px;
                        &-header {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                        }
                        &-desc {
                            margin-top: 8px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }

                    .par3 {
                        margin-top: 0px;
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 3% 0 3%;
            margin-top: -10px;

            .tt2-container-footer-text {
                font-size: 14px;
                line-height: 17px;
            }

            &-button {
                margin-top: 20px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}






@media screen and (min-width: 311px) and (max-width: 375px) {
    .tt2-container {
        margin: 100px 0 100px 0;

        .tt2-container-header {
            padding: 0 3% 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3% 0 3%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    img {
                        height: 250px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 15px;
                    &-par {
                        width: 250px;
                        height: 111px;
                        &-header {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                        }
                        &-desc {
                            margin-top: 8px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 3% 0 3%;
            margin-top: 0px;

            .tt2-container-footer-text {
                font-size: 14px;
                line-height: 17px;
            }

            &-button {
                margin-top: 20px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}









@media screen and (min-width: 0px) and (max-width: 310px) {
    .tt2-container {
        margin: 100px 0 100px 0;

        .tt2-container-header {
            padding: 0 3% 0 3%;
            width: 94%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3% 0 3%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    img {
                        height: 250px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 15px;
                    gap: 50px;
                    display: grid;

                    &-par {
                        width: 200px;

                        &-header {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                        }
                        &-desc {
                            margin-top: 8px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 3% 0 3%;
            margin-top: 0px;

            .tt2-container-footer-text {
                font-size: 14px;
                line-height: 17px;
            }

            &-button {
                margin-top: 20px;
                position: relative;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: white;
                background: #3F778A;
            }
        }
    }
}