@media screen and (min-width: 1150px) {
    .fa-container {
        width: 100%;
        margin: 150px 0;
    
        .fa-container-header {
            padding: 0 5%;
        }
    
        &-content {
    
            &-text {
                color: rgba(81, 81, 81, 1);
                margin-top: 60px;
                padding: 0 5%;
                width: 560px;
            }
    
            &-tabs {
                margin-top: 40px;
    
                .tab {
                    padding: 30px 5%;
                    border-bottom: 1px solid;
        
                    border-image: linear-gradient(
                        to left,
                        white,
                        rgba(63, 119, 138, 1),
                        white
                    ) 100% 0 100% 0/0 0 1px 0 stretch;
        
                    &-header {
                        cursor: pointer;
                        color: rgba(63, 119, 138, 1);
                        padding: 0 5% 0 5%;
                        display: flex;
        
                        &-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 32px;
                            line-height: 39px;
                        }
                        &-arrow {
                            line-height: 60px;
                            position: absolute;
                            left: 1100px;
        
                            .rotate-90 { 
                                transform: rotate(-90deg);
                            }
                        }
                    }
        
                    &-content {
                        display: none;
                        margin: 40px 5% 0 5%;
        
                        .par {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
    
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            width: 644px;
                        }
                        ul {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                            padding-left: 25px;
                            margin: 20px 0;
                        }
                    }
        
                    .show-content {
                        display: block;
                    }
                }
        
                .selected-tab {
                    padding: 40px 5%;
                    background-color: #3F778A;
                    .tab-header-text {
                        color: white;
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 1025px) and (max-width: 1150px) {
    .fa-container {
        width: 100%;
        margin: 150px 0;
    
        .fa-container-header {
            padding: 0 5%;
        }
    
        &-content {
    
            &-text {
                color: rgba(81, 81, 81, 1);
                margin-top: 60px;
                padding: 0 5%;
                width: 560px;
            }
    
            &-tabs {
                margin-top: 40px;
    
                .tab {
                    padding: 30px 5%;
                    border-bottom: 1px solid;
        
                    border-image: linear-gradient(
                        to left,
                        white,
                        rgba(63, 119, 138, 1),
                        white
                    ) 100% 0 100% 0/0 0 1px 0 stretch;
        
                    &-header {
                        cursor: pointer;
                        color: rgba(63, 119, 138, 1);
                        padding: 0 5% 0 5%;
                        display: flex;
        
                        &-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 32px;
                            line-height: 39px;
                        }
        
                        &-arrow {
                            line-height: 60px;
                            position: absolute;
                            // left: 1100px;
                            right: 10px;
        
                            .rotate-90 { 
                                transform: rotate(-90deg);
                            }
                        }
                    }
        
                    &-content {
                        display: none;
                        margin: 40px 5% 0 5%;
        
                        .par {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
    
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            width: 644px;
                        }
    
                        ul {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                            padding-left: 25px;
                            margin: 20px 0;
                        }
                    }
        
                    .show-content {
                        display: block;
                    }
                }
        
                .selected-tab {
                    padding: 40px 5%;
                    background-color: #3F778A;
                    .tab-header-text {
                        color: white;
                    }
                }
            }
        }
    }
}




@media screen and (min-width: 801px) and (max-width: 1024px) {
    .fa-container {
        width: 100%;
        margin: 150px 0;
    
        .fa-container-header {
            padding: 0 5%;
        }
    
        &-content {
    
            &-text {
                color: rgba(81, 81, 81, 1);
                margin-top: 60px;
                padding: 0 5%;
                width: 560px;
            }
    
            &-tabs {
                margin-top: 40px;
    
                .tab {
                    padding: 30px 5%;
                    border-bottom: 1px solid;
        
                    border-image: linear-gradient(
                        to left,
                        white,
                        rgba(63, 119, 138, 1),
                        white
                    ) 100% 0 100% 0/0 0 1px 0 stretch;
        
                    &-header {
                        cursor: pointer;
                        color: rgba(63, 119, 138, 1);
                        padding: 0 5% 0 5%;
                        display: flex;
        
                        &-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 32px;
                            line-height: 39px;
                        }
        
                        &-arrow {
                            line-height: 60px;
                            position: absolute;
                            left: 850px;
        
                            .rotate-90 { 
                                transform: rotate(-90deg);
                            }
                        }
                    }
        
                    &-content {
                        display: none;
                        margin: 40px 5% 0 5%;
        
                        .par {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
    
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            width: 644px;
                        }
    
                        ul {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                            padding-left: 25px;
                            margin: 20px 0;
                        }
                    }
        
                    .show-content {
                        display: block;
                    }
                }
        
                .selected-tab {
                    padding: 40px 5%;
                    background-color: #3F778A;
                    .tab-header-text {
                        color: white;
                    }
                }
            }
        }
    }
}






@media screen and (min-width: 481px) and (max-width: 800px) {
    .fa-container {
        width: 100%;
        margin: 150px 0;
    
        .fa-container-header {
            padding: 0 5%;
        }
    
        &-content {
    
            &-text {
                color: rgba(81, 81, 81, 1);
                margin-top: 60px;
                padding: 0 5%;
            }
    
            &-tabs {
                margin-top: 40px;
    
                .tab {
                    padding: 30px 5%;
                    border-bottom: 1px solid;
        
                    border-image: linear-gradient(
                        to left,
                        white,
                        rgba(63, 119, 138, 1),
                        white
                    ) 100% 0 100% 0/0 0 1px 0 stretch;
        
                    &-header {
                        cursor: pointer;
                        color: rgba(63, 119, 138, 1);
                        padding: 0 0% 0 0%;
                        display: flex;
        
                        &-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 32px;
                            line-height: 39px;
                        }
        
                        &-arrow {
                            line-height: 60px;
                            margin-left: 50px;
        
                            .rotate-90 { 
                                transform: rotate(-90deg);
                            }
                        }
                    }
        
                    &-content {
                        display: none;
                        margin: 40px 5% 0 5%;
        
                        .par {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
    
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            // width: 644px;
                        }
    
                        ul {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                            padding-left: 25px;
                            margin: 20px 0;
                        }
                    }
        
                    .show-content {
                        display: block;
                    }
                }
        
                .selected-tab {
                    padding: 40px 5%;
                    background-color: #3F778A;
                    .tab-header-text {
                        color: white;
                    }
                }
            }
        }
    }
}






@media screen and (min-width: 0px) and (max-width: 480px) {
    .fa-container {
        width: 100%;
        margin: 150px 0;
    
        .fa-container-header {
            padding: 0 3%;
            font-size: 36px;
            line-height: 46px;
        }
    
        &-content {
    
            &-text {
                color: rgba(81, 81, 81, 1);
                margin-top: 40px;
                padding: 0 3%;
            }
    
            &-tabs {
                margin-top: 40px;
    
                .tab {
                    padding: 30px 3%;
                    border-bottom: 1px solid;
        
                    border-image: linear-gradient(
                        to left,
                        white,
                        rgba(63, 119, 138, 1),
                        white
                    ) 100% 0 100% 0/0 0 1px 0 stretch;
        
                    &-header {
                        cursor: pointer;
                        color: rgba(63, 119, 138, 1);
                        padding: 0 0% 0 0%;
                        display: flex;
                        width: 100%;
                        position: relative;
        
                        &-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            width: 90%;
                        }
        
                        &-arrow {
                            line-height: 60px;
                            margin-left: 0px;
                            position: relative;

                            img {
                                position: absolute;
                                top: 6px;
                                left: 7px;
                                height: 20px;
                            }
        
                            .rotate-90 { 
                                transform: rotate(-90deg);
                            }
                        }
                    }
        
                    &-content {
                        display: none;
                        margin: 40px 3% 0 3%;
        
                        .par {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
    
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            // width: 644px;
                        }
    
                        ul {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                            padding-left: 25px;
                            margin: 20px 0;
                        }
                    }
        
                    .show-content {
                        display: block;
                    }
                }
        
                .selected-tab {
                    padding: 40px 3%;
                    background-color: #3F778A;
                    .tab-header-text {
                        color: white;
                    }
                    img {
                        // width: 20px;
                        height: 12px;
                    }
                }
            }
        }
    }
}