@media screen and (min-width: 1601px) {
    .tt-container {
        margin: 100px 0 100px 0;

        .header {
            padding: 0 5% 0 5%;
            width: 900px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 60px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;
                    
                    &-par {
                        width: 450px;
                        height: 133px;

                        &-header {
                            font-weight: 700;
                            color: rgba(0, 0, 0, 1);
                        }
                        &-desc {
                            color: rgba(81, 81, 81, 1);
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                position: relative;
                width: 50%;

                img {
                    position: absolute;
                    width: 600px;
                    margin: 5% 20px 0 20%;
                    z-index: 10;
                }
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: 0px;
            color: rgba(0, 0, 0, 1);

            &-button {
                position: relative;
                margin-top: 40px;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                height: 20px;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                background: #3F778A;
                color: white;
            }
        }
    }
}




// ==============   < 1600   =================



@media screen and (max-width: 1601px) and (min-width: 1025px) {
    .tt-container {
        margin: 100px 0 100px 0;

        .header {
            padding: 0 5% 0 5%;
            width: 900px;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 60px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 10%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 30px;

                    &-par {
                        width: 450px;
                        height: 133px;

                        &-header {
                            font-weight: 700;
                            color: rgba(0, 0, 0, 1);
                        }
                        &-desc {
                            color: rgba(81, 81, 81, 1);
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                position: relative;
                width: 50%;

                img {
                    position: absolute;
                    width: 450px;
                    margin: 5% 10px 0 0%;
                    right: 0;
                    z-index: 10;
                }
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: 0px;
            color: rgba(0, 0, 0, 1);

            &-button {
                position: relative;
                margin-top: 40px;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                height: 20px;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                background: #3F778A;
                color: white;
            }
        }
    }
}






// ==============   < 1024   =================



@media screen and (max-width: 1025px) and (min-width: 951px) {
    .tt-container {
        margin: 50px 0 100px 0;

        .header {
            padding: 0 5% 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 60px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;

                .steps-image {
                    padding-left: 0%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    margin-top: 5px;
                    padding-left: 20px;

                    &-par {
                        width: 450px;
                        height: 133px;

                        &-header {
                            font-weight: 700;
                            color: rgba(0, 0, 0, 1);
                        }
                        &-desc {
                            color: rgba(81, 81, 81, 1);
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                position: relative;
                width: 50%;

                img {
                    position: absolute;
                    width: 430px;
                    margin: 10% 10px 0 6%;
                    z-index: 10;
                }
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: 0px;
            color: rgba(0, 0, 0, 1);

            &-button {
                position: relative;
                margin-top: 20px;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                height: 20px;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                background: #3F778A;
                color: white;
            }
        }
    }
}





// ==============   < 950   =================



@media screen and (max-width: 951px) and (min-width: 481px) {
    .tt-container {
        margin: 100px 0 100px 0;

        .header {
            padding: 0 5% 0 5%;
            width: 90%;
        }

        &-content {
            padding: 0 5% 0 5%;
            margin-top: 60px;
            display: flex;
            // justify-content: center;

            &-steps {
                width: 50%;
                display: flex;
                width: 100%;

                .steps-image {
                    padding-left: 0%;
                    img {
                        height: 300px;
                    }
                }

                .steps-text {
                    width: 70%;
                    margin-top: 5px;
                    padding-left: 20px;

                    &-par {
                        height: 133px;
                        &-header {
                            font-weight: 700;
                            color: rgba(0, 0, 0, 1);
                        }
                        &-desc {
                            color: rgba(81, 81, 81, 1);
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 5% 0 5%;
            margin-top: 0px;
            color: rgba(0, 0, 0, 1);

            &-button {
                position: relative;
                margin-top: 20px;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                height: 20px;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                background: #3F778A;
                color: white;
            }
        }
    }
}





// ==============   < 480   =================



@media screen and (max-width: 481px) and (min-width: 0px) {
    .tt-container {
        margin: 0px 0 100px 0;

        .header {
            padding: 0 3% 0 3%;
            width: 90%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3% 0 3%;
            margin-top: 40px;
            display: flex;

            &-steps {
                width: 50%;
                display: flex;
                width: 100%;

                .steps-image {
                    padding-left: 0%;
                    img {
                        height: 250px;
                    }
                }

                .steps-text {
                    width: 70%;
                    margin-top: 5px;
                    padding-left: 20px;

                    &-par {
                        height: 110px;
                        &-header {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            color: rgba(0, 0, 0, 1);
                        }
                        &-desc {
                            color: rgba(81, 81, 81, 1);
                            margin-top: 8px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }

            &-bages {
                display: none;
            }
        }

        &-footer {
            padding: 0 3% 0 3%;
            margin-top: 0px;
            color: rgba(0, 0, 0, 1);

            .text {
                font-size: 14px;
                line-height: 17px;
            }

            &-button {
                position: relative;
                margin-top: 20px;
                cursor: pointer;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                height: 20px;
                align-items: center;
                padding: 18px 30px;
                gap: 10px;
                border-radius: 100px;
                
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                background: #3F778A;
                color: white;
            }
        }
    }
}