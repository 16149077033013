.swiper-header {
    color: rgba(255, 255, 255, 1);
}
.second-header {
    color: white;
}
.swiper-desc {
    color: rgba(255, 255, 255, 0.7);
}

.w-1100 {
    width: 1200px!important;
}
@media (max-width: 1025px) {
    .w-1100 {
        width: 750px!important;
    }
}
@media (max-width: 1001px) {
    .w-1100 {
        width: 100%!important;
    }
}
@media (max-width: 769px) {
    .w-1100 {
        width: 550px!important;
    }
}
@media (max-width: 641px) {
    .w-1100 {
        width: 460px!important;
    }
}
@media (max-width: 481px) {
    .w-1100 {
        width: 371px!important;
    }
}
@media (max-width: 381px) {
    .w-1100 {
        width: 311px!important;
    }
}
@media (max-width: 326px) {
    .w-1100 {
        width: 270px!important;
    }
}

@media screen and (min-width: 1441px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 670px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 10% 0 10%;
                margin-top: 50px;
                text-align: left;

                .swiper-header {
                    font-size: 86px;
                    line-height: 103px;
                }

                &-header {
                    &-en {
                        width: 900px;
                        margin-top: 10px;
                    }
                    &-ru {
                        width: 1100px;
                        margin-top: 10px;
                    }
                    &-es {
                        width: 900px;
                        font-size: 75px!important;
                        line-height: 90px!important;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 600px;
                        
                    }
                }
                .second-header {
                    font-size: 50px!important;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            left: 45%;
            top: 50px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 120px;
                filter: blur(1.5px);
                left: 430px;
                top: 130px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 130px;
                left: 250px;
                top: 240px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(140px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 140px;
                left: 390px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(130px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 110px;
                filter: blur(2px);
                left: 300px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(160px); transition: all 1.5s; }
                &-3-slide { transform: translateX(250px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 190px;
                left: 60px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}






// ============   < 1440   =================





@media screen and (max-width: 1441px) and (min-width: 1281px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 670px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 10% 0 10%;
                margin-top: 50px;
                text-align: left;

                .swiper-header {
                    font-size: 86px;
                    line-height: 103px;
                }

                &-header {
                    &-en {
                        width: 900px;
                        margin-top: 10px;
                    }
                    &-ru {
                        width: 1100px;
                        margin-top: 10px;
                    }
                    &-es {
                        width: 900px;
                        font-size: 75px!important;
                        line-height: 90px!important;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 600px;
                        
                    }
                }
                .second-header {
                    font-size: 50px!important;
                }
                .control-dots {
                    background-color: red;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            left: 40%;
            top: 50px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 120px;
                filter: blur(1.5px);
                left: 430px;
                top: 130px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 130px;
                left: 250px;
                top: 240px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(140px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 140px;
                left: 390px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(130px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 110px;
                filter: blur(2px);
                left: 300px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(160px); transition: all 1.5s; }
                &-3-slide { transform: translateX(250px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 190px;
                left: 60px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}






// ============   < 1280   =================





@media screen and (max-width: 1281px) and (min-width: 1201px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 610px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 5% 0 5%;
                margin-top: 60px;
                text-align: left;

                .swiper-header {
                    font-size: 86px;
                    line-height: 103px;
                }

                &-header {
                    &-en {
                        width: 900px;
                        margin-top: 10px;
                    }
                    &-ru {
                        width: 1100px;
                        margin-top: 10px;
                        font-size: 50px;
                        line-height: 55px;
                    }
                    &-es {
                        width: 900px;
                        font-size: 75px!important;
                        line-height: 90px!important;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 600px;
                        
                    }
                }
                .second-header {
                    font-size: 50px!important;
                }
                .control-dots {
                    background-color: red;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            left: 37%;
            top: 50px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 120px;
                filter: blur(1.5px);
                left: 430px;
                top: 130px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 130px;
                left: 250px;
                top: 240px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(140px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 140px;
                left: 390px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(130px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 110px;
                filter: blur(2px);
                left: 300px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(160px); transition: all 1.5s; }
                &-3-slide { transform: translateX(250px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 190px;
                left: 60px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}






// ============   < 1200   =================





@media screen and (max-width: 1201px) and (min-width: 1025px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 610px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 5% 0 5%;
                margin-top: 60px;
                text-align: left;

                .swiper-header {
                    font-size: 86px;
                    line-height: 103px;
                }

                &-header {
                    &-en {
                        width: 900px;
                        margin-top: 10px;
                    }
                    &-ru {
                        width: 1100px;
                        margin-top: 10px;
                        font-size: 50px;
                        line-height: 55px;
                    }
                    &-es {
                        width: 900px;
                        font-size: 75px!important;
                        line-height: 90px!important;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 600px;
                        
                    }
                }
                .second-header {
                    font-size: 50px!important;
                }
                .control-dots {
                    background-color: red;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            left: 37%;
            top: 50px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 120px;
                filter: blur(1.5px);
                left: 430px;
                top: 130px;

                &-1-slide { transform: translateX(20px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(90px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 130px;
                left: 220px;
                top: 240px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(80px); transition: all 1.5s; }
                &-3-slide { transform: translateX(160px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 140px;
                left: 350px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(130px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 110px;
                filter: blur(2px);
                left: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(120px); transition: all 1.5s; }
                &-3-slide { transform: translateX(160px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 190px;
                left: 60px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}







// ============   < 1024   =================





@media screen and (max-width: 1025px) and (min-width: 1001px) {
    .swiper-container {

        .swipes {
            width: 100%;
            height: 700px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 5% 0 5%;
                margin-top: 60px;
                text-align: left;

                &-header {
                    &-en {
                        width: 750px;
                        margin-top: 10px;
                    }
                    &-ru {
                        width: 560px;
                        margin-top: 10px;
                        font-size: 50px;
                        line-height: 55px;
                    }
                    &-es {
                        width: 750px;
                        margin-top: 10px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 600px;
                        
                    }
                }

                .control-dots {
                    background-color: red;
                }
            }
        }

        width: 100%;
        height: 70%;
        position: relative;

        .coins {
            position: absolute;
            z-index: 5;
            left: 25%;
            top: 150px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 120px;
                filter: blur(1.5px);
                left: 430px;
                top: 130px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 130px;
                left: 250px;
                top: 240px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(140px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 140px;
                left: 390px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(130px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 110px;
                filter: blur(2px);
                left: 300px;
                top: -200px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(160px); transition: all 1.5s; }
                &-3-slide { transform: translateX(250px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 190px;
                left: 60px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}






// ============   < 1000   =================





@media screen and (max-width: 1001px) and (min-width: 769px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 750px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 5% 0 5%;
                margin-top: 60px;
                text-align: left;

                &-header {
                    &-en {
                        width: 730px;
                    }
                    &-ru {
                        width: 570px;
                        font-size: 50px;
                        line-height: 55px;
                    }
                    &-es {
                        width: 730px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                }

                .control-dots {
                    background-color: red;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            left: 20%;
            top: 200px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 120px;
                filter: blur(1.5px);
                left: 380px;
                top: 130px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(90px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 130px;
                left: 250px;
                top: 240px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(140px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 140px;
                left: 340px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(120px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 110px;
                filter: blur(2px);
                left: 300px;
                top: -260px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(120px); transition: all 1.5s; }
                &-3-slide { transform: translateX(180px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 190px;
                left: 60px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}






// ============   < 769   =================





@media screen and (max-width: 769px) and (min-width: 641px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 670px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 5% 0 5%;
                margin-top: 40px;
                text-align: left;

                &-header {
                    &-en {
                        width: 550px;
                        margin-top: 10px;
                        font-size: 50px;
                        line-height: 60px;
                    }
                    &-ru {
                        width: 450px;
                        font-size: 40px;
                        line-height: 50px;
                    }
                    &-es {
                        width: 550px;
                        font-size: 45px;
                        line-height: 50px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        
                        width: 470px;
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                }

                .second-header-ru {
                    font-size: 35px;
                    line-height: 45px;
                }

                .control-dots {
                    background-color: red;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            // left: 25%;
            top: 130px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 80px;
                // filter: blur(1px);
                left: 340px;
                top: 70px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 90px;
                left: 140px;
                top: 280px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(140px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 100px;
                left: 230px;
                top: 350px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(130px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 70px;
                // filter: blur(2px);
                left: 300px;
                top: 260px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(140px); transition: all 1.5s; }
                &-3-slide { transform: translateX(200px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 150px;
                left: 0px;
                top: 360px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}







// ============   < 640   =================





@media screen and (max-width: 641px) and (min-width: 481px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 750px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 5% 0 5%;
                margin-top: 0px;
                text-align: left;

                &-header {
                    &-en {
                        width: 460px;
                        margin-top: 0px;
                        font-size: 50px;
                        line-height: 60px;
                    }
                    &-ru {
                        width: 460px;
                        font-size: 40px;
                        line-height: 50px;
                    }
                    &-es {
                        width: 460px;
                        font-size: 45px;
                        line-height: 50px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        
                        width: 470px;
                    }
                    &-ru {
                        margin-top: 20px;
                        width: 470px;
                        
                    }
                }

                .second-header-ru {
                    font-size: 35px;
                    line-height: 45px;
                }

                .control-dots {
                    background-color: red;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            // left: 25%;
            top: 400px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 60px;
                // filter: blur(1px);
                left: 200px;
                top: 0px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 90px;
                left: 90px;
                top: 120px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(90px); transition: all 1.5s; }
                &-3-slide { transform: translateX(180px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 100px;
                left: 170px;
                top: 180px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(70px); transition: all 1.5s; }
                &-3-slide { transform: translateX(150px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 70px;
                // filter: blur(2px);
                left: 220px;
                top: 80px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(120px); transition: all 1.5s; }
                &-3-slide { transform: translateX(170px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 140px;
                left: 0px;
                top: 200px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(40px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}







// ============   < 480   =================





@media screen and (max-width: 481px) and (min-width: 381px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 670px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 3% 0 3%;
                margin-top: 0px;
                text-align: left;

                &-header {
                    &-en {
                        width: 370px;
                        margin-top: 0px;
                        font-size: 48px;
                        line-height: 60px;
                    }
                    &-ru {
                        width: 370px;
                        font-size: 36px;
                        line-height: 42px;
                    }
                    &-es {
                        width: 370px;
                        margin-top: 0px;
                        font-size: 36px;
                        line-height: 42px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        
                        width: 370px;
                    }
                    &-ru {
                        margin-top: 10px;
                        width: 370px;
                        
                        line-height: 20px;
                    }
                }

                .second-header {
                    font-size: 36px;
                    line-height: 42px;
                }
                .second-header-ru {
                    font-size: 32px;
                    line-height: 40px;
                }
                
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            // left: 25%;
            top: 250px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 50px;
                filter: blur(1.2px);
                left: 200px;
                top: 0px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(30px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 80px;
                left: 80px;
                top: 180px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 90px;
                left: 160px;
                top: 250px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 50px;
                // filter: blur(2px);
                left: 200px;
                top: 160px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(90px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 120px;
                left: -10px;
                top: 260px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}







// ============   < 380   =================





@media screen and (max-width: 381px) and (min-width: 326px) {
    .swiper-container {
        width: 100%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 610px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 3% 0 3%;
                margin-top: 0px;
                text-align: left;

                &-header {
                    &-en {
                        width: 310px;
                        margin-top: 0px;
                        font-size: 46px;
                        line-height: 58px;
                    }
                    &-ru {
                        width: 310px;
                        font-size: 36px;
                        line-height: 42px;
                    }
                    &-es {
                        width: 310px;
                        font-size: 36px;
                        line-height: 42px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        
                        width: 310px;
                    }
                    &-ru {
                        margin-top: 10px;
                        width: 310px;
                        
                        line-height: 20px;
                    }
                }

                .second-header {
                    font-size: 36px;
                    line-height: 42px;
                }
                .second-header-ru {
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }

        .coins {
            position: absolute;
            z-index: 5;
            // left: 25%;
            left: 5px;
            top: 290px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 40px;
                filter: blur(1.2px);
                left: 200px;
                top: 0px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(30px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 60px;
                left: 80px;
                top: 140px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 70px;
                left: 160px;
                top: 170px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(60px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 50px;
                // filter: blur(2px);
                left: 200px;
                top: 100px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(90px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 100px;
                left: -10px;
                top: 200px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}






// ============   < 325   =================





@media screen and (max-width: 326px) and (min-width: 0px) {
    .swiper-container {
        width: 94%;
        height: 70%;
        position: relative;

        .swipes {
            width: 100%;
            height: 540px;
            display: flex;
            position: absolute;
            z-index: 10;

            .swipe {
                padding: 0 3% 0 3%;
                margin-top: 0px;
                text-align: left;

                &-header {
                    &-en {
                        width: 270px;
                        margin-top: 0px;
                        font-size: 26px;
                        line-height: 30px;
                    }
                    &-ru {
                        width: 270px;
                        margin-top: 0px;
                        font-size: 26px;
                        line-height: 30px;
                    }
                    &-es {
                        width: 270px;
                        margin-top: 0px;
                        font-size: 26px;
                        line-height: 30px;
                    }
                }
                &-description {
                    &-en {
                        margin-top: 20px;
                        
                        width: 270px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    &-ru {
                        margin-top: 20px;
                        
                        width: 270px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                .second-header {
                    font-size: 20px;
                    line-height: 32px;
                }
                .second-header-ru {
                    font-size: 20px;
                    line-height: 32px;
                }
            }
        }
        
        // .swipes {
        //     display: flex;
        //     position: absolute;
        //     z-index: 10;

        //     .swipe {
        //         position: relative;
        //         display: block;
        //         margin-top: 0px;

        //         &-header-en {
        //             width: 270px;
        //             margin-top: 0px;
        //             font-size: 32px;
        //             line-height: 40px;
        //         }
        //         .second-header {
        //             font-size: 24px;
        //             line-height: 32px;
        //         }
        //         &-description-en {
        //             margin-top: 10px;
        //             
        //             width: 270px;
        //         }
                
        //         &-1 {
        //             &-default { left: 0; opacity: 1; }
        //             &-go-left { animation: slide-1-go-left 1.5s linear 1 0s forwards; }
        //             &-go-right { animation: slide-1-go-right 1.5s linear 1 0s forwards; }
        //         }
        //         &-2 {
        //             &-default { left: 100%; opacity: 0; }
        //             &-go-left { animation: slide-2-go-left 1.5s linear 1 0s forwards; }
        //             &-go-left-from-center { animation: slide-2-go-left-from-center 1.5s linear 1 0s forwards; }
        //             &-go-right { animation: slide-2-go-right 1.5s linear 1 0s forwards; }
        //             &-go-right-from-center { animation: slide-2-go-right-from-center 1.5s linear 1 0s forwards; }
        //         }
        //         &-3 {
        //             &-default { left: 100%; opacity: 0; }
        //             &-go-left { animation: slide-3-go-left 1.5s linear 1 0s forwards; }
        //             &-go-right { animation: slide-3-go-right 1.5s linear 1 0s forwards; }
        //         }

        //         @keyframes slide-1-default {
        //             0% { left: -1000px; opacity: 0; }
        //             100% { left: 0; opacity: 1; }
        //         }
        //         @keyframes slide-1-go-left {
        //             0% { left: 0; opacity: 1; }
        //             100% { left: -1000px; opacity: 0; }
        //         }
        //         @keyframes slide-1-go-right {
        //             0% { left: -1000px; opacity: 0; }
        //             100% { left: 0; opacity: 1; }
        //         }

        //         @keyframes slide-2-go-left {
        //             0% { left: 50%; opacity: 0; }
        //             100% { left: -33%; opacity: 1; }
        //         }
        //         @keyframes slide-2-go-left-from-center {
        //             0% { left: -33%; opacity: 1; }
        //             100% { left: -80%; opacity: 0; }
        //         }
        //         @keyframes slide-2-go-right {
        //             0% { left: -80%; opacity: 0; display: none; }
        //             100% { left: -33%; opacity: 1; display: none; }
        //         }
        //         @keyframes slide-2-go-right-from-center {
        //             0% { left: -33%; opacity: 1; }
        //             100% { left: 50%; opacity: 0; }
        //         }

        //         @keyframes slide-3-go-left {
        //             0% { left: 10%; opacity: 0; }
        //             100% { left: -67%; opacity: 1; }
        //         }
        //         @keyframes slide-3-go-right {
        //             0% {left: -67%; opacity: 1; }
        //             100% { left: 10%; opacity: 0; }
        //         }
        //     }
        // }

        .coins {
            position: absolute;
            z-index: 5;
            // left: 25%;
            top: 275px;
            left: 5px;
            height: 100px;
            width: 100px;

            .coin-start {
                position: absolute;
            }

            .coin-start-apple {
                width: 40px;
                filter: blur(1.2px);
                left: 120px;
                top: 40px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(30px); transition: all 1.5s; }
                &-3-slide { transform: translateX(100px); transition: all 1.5s; }
            }

            .coin-start-bitcoin {
                width: 50px;
                left: 80px;
                top: 110px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(30px); transition: all 1.5s; }
                &-3-slide { transform: translateX(70px); transition: all 1.5s; }
            }
            
            .coin-start-ethereum {
                width: 60px;
                left: 140px;
                top: 160px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(60px); transition: all 1.5s; }
            }

            .coin-start-tesla {
                width: 40px;
                // filter: blur(2px);
                left: 160px;
                top: 100px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(30px); transition: all 1.5s; }
                &-3-slide { transform: translateX(60px); transition: all 1.5s; }
            }

            .coin-start-facebook {
                width: 80px;
                left: -10px;
                top: 160px;

                &-1-slide { transform: translateX(0px); transition: all 1.5s; }
                &-2-slide { transform: translateX(50px); transition: all 1.5s; }
                &-3-slide { transform: translateX(80px); transition: all 1.5s; }
            }
        }
    }
}