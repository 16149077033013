:root {
    --ss-bg: rgba(63, 119, 138, 1);
    --live-acc-color: #000000;
    --live-acc-bg: white;
    --live-acc-shadow: 0px 4px 6px #2F5C6B;
}

.ss-container {
    background-color: var(--ss-bg);
}

@media screen and (min-width: 1537px) {
    .ss-container {

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        height: 100%;
        position: relative;
        z-index: 5;

        &-swiper {
            display: flex;
            height: 400px;
            position: relative;

            .swiper-border {
                width: 10%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 0px 0 0 10%;

            .live-account {
                text-decoration: none;
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
                text-align: center;
                padding: 18px 30px;
                
                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }  
        }

        &-footer {
            display: flex;
            margin-top: 170px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;
                    z-index: 999;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}







@media screen and (min-width: 1282px) and (max-width: 1536px) {
    .ss-container {

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    animation: selected-slide 1s linear 1 0s forwards;
                    // border: 1px solid red;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        height: 100%;
        position: relative;
        z-index: 5;

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 400px;
            position: relative;

            .swiper-border {
                width: 10%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 0px 0 0 10%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 18px 30px;
                margin-bottom: 20px;
                gap: 10px;
                text-align: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 130px;
            // margin-bottom: 50px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;
                    z-index: 999;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}







// =============   < 1280   ===============





@media screen and (max-width: 1281px) and (min-width: 1025px) {
    .ss-container {

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        height: 100%;
        position: relative;
        z-index: 5;

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 330px;
            position: relative;

            .swiper-border {
                width: 5%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 75px 0 0 5%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 14px 30px;
                gap: 10px;
                text-align: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 100px;
            // margin-bottom: 30px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;
                z-index: 999;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}






// =============   < 1024   ===============





@media screen and (max-width: 1025px) and (min-width: 1001px) {
    .ss-container {

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        height: 100%;
        position: relative;
        z-index: 5;

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 360px;
            position: relative;
            // margin-top: 70px;
            padding-top: 60px;

            .swiper-border {
                width: 5%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 40px 0 0 5%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 14px 30px;
                gap: 10px;
                text-align: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 250px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}






// =============   < 1000   ===============





@media screen and (max-width: 1001px) and (min-width: 769px) {
    .ss-container {
        height: 100%;
        position: relative;
        z-index: 5;

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 360px;
            position: relative;
            // margin-top: 70px;
            padding-top: 60px;

            .swiper-border {
                width: 5%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 50px 0 0 5%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 14px 30px;
                gap: 10px;
                text-align: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 250px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}






// =============   < 768   ===============





@media screen and (max-width: 769px) and (min-width: 641px) {
    .ss-container {
        height: 100%;
        position: relative;
        z-index: 5;

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid white;
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 400px;
            position: relative;
            // margin-top: 70px;
            padding-top: 0px;

            .swiper-border {
                width: 5%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 0px 0 0 5%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 14px 30px;
                gap: 10px;
                text-align: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 200px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}








// =============   < 640   ===============





@media screen and (max-width: 641px) and (min-width: 481px) {
    .ss-container {
        height: 100%;
        position: relative;
        z-index: 5;

        .carousel {
            .control-dots {
                padding: 0 5% 0 5%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid white;
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 400px;
            position: relative;
            // margin-top: 70px;
            padding-top: 0px;

            .swiper-border {
                width: 5%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            display: flex;
            margin: 60px 0 0 5%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 14px 30px;
                gap: 10px;
                text-align: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 200px;
            padding: 0 5% 0 5%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}







// =============   < 480   ===============





@media screen and (max-width: 481px) and (min-width: 381px) {
    .ss-container {
        height: 100%;
        position: relative;
        z-index: 5;


        .carousel {
            .control-dots {
                padding: 0 3% 0 3%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid white;
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 320px;
            position: relative;
            // margin-top: 70px;
            padding-top: 0px;

            .swiper-border {
                width: 3%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            // display: flex;
            // margin: 60px 3% 0 3%;
            padding: 0 3% 0 3%;
            width: 94%;
            // margin-top: 50px;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                flex-direction: row;
                padding: 14px 30px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 200px;
            padding: 50px 3% 50px 3%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}








// =============   < 380   ===============





@media screen and (max-width: 381px) and (min-width: 326px) {
    .ss-container {
        height: 100%;
        position: relative;
        z-index: 5;

        .carousel {
            .control-dots {
                padding: 0 3% 0 3%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid white;
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 300px;
            position: relative;
            margin-top: -25px;

            .swiper-border {
                width: 3%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            // display: flex;
            // margin: 60px 3% 0 3%;
            padding: 40px 3% 0 3%;
            width: 94%;
            // margin-top: 50px;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                flex-direction: row;
                padding: 14px 30px;

                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 40px;
            padding: 50px 3% 90px 3%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}





// =============   < 325   ===============





@media screen and (max-width: 326px) and (min-width: 0px) {
    .ss-container {
        height: 100%;
        position: relative;
        z-index: 5;
        margin-bottom: 60px;

        .carousel {
            .control-dots {
                padding: 0 3% 0 3%;
                text-align: right;

                .dot {
                    opacity: 1;
                    box-sizing: border-box;
                    cursor: pointer;
                    width: 6px;
                    height: 6px;
                    // margin-right: 10px;
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    background-color: transparent;
                    box-shadow: none;
                }

                .dot.selected {
                    margin-right: 10px;
                    box-sizing: border-box;
                    width: 60px;
                    height: 6px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 50px;
                    border: 1px solid rgba(255, 255, 255, 1);
                    animation: selected-slide 1s linear 1 0s forwards;
                }

                @keyframes selected-slide {
                    0% { width: 6px; } 100% { width: 60px; }
                }
            }
        }

        .bg-line {
            width: 100%;
            height: 80%;
            position: absolute;
            z-index: 1;

            filter: blur(50px);
        }

        &-swiper {
            display: flex;
            height: 220px;
            position: relative;
            margin-top: -25px;

            .swiper-border {
                width: 3%;
                height: 500px;
                margin-top: 0px;
                position: relative;
                z-index: 50;
                cursor: pointer;
            }
        }

        &-buttons {
            padding: 60px 3% 0 3%;
            width: 94%;

            .live-account {
                position: relative;
                z-index: 10;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                flex-direction: row;
                padding: 14px 30px;

                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--live-acc-color);
                background: var(--live-acc-bg);
                box-shadow: var(--live-acc-shadow);
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &-footer {
            display: flex;
            margin-top: 40px;
            padding: 10px 3% 50px 3%;

            &-links {
                display: flex;
                display: none;

                .ss-links-item {
                    margin-right: 40px;
                    display: flex;
                    cursor: pointer;

                    &-text {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: rgba(84, 222, 253, 1);
                        margin-left: 5px;
                    }
                }
                .ss-links-item:hover .ss-links-item-text{
                    color: white;
                }
            }
        }
    }
}
