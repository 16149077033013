.description-first {
    color: rgba(81, 81, 81, 1);
}

@media screen and (min-width: 1301px) {
    .rb-container {
        margin-top: 10%;
        width: 100%;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            width: 800px;
        }

        &-content {
            padding: 0 5% 0 5%;
            display: flex;

            .info {
                margin-top: 18px;

                .description {
                    max-width: 560px;
                    color: #A5A5A5;
                    
                    &-block {
                        margin-bottom: 20px;
                    }

                    .download {
                        margin-top: 30px;
                        &-text {
                            color: white;
                        }
                        &-platforms {
                            margin-top: 20px;
                            &-item {
                                padding-right: 12%;
                                height: 60px;
                            }
                        }

                        .trade-now-btn {
                            margin-top: 30px;

                            position: relative;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            width: 167px;
                            padding: 18px 30px;
                            border-radius: 100px;
                            color: white;
                            background: #3F778A;
                        }
                    }
                }
            }
        }
            
        .map-glow {
            position: relative;
            width: 50%;
            margin: 4% 0 0 16%;

            .map {
                width: 600px;
                position: absolute;
            }
        }
    }
}




@media screen and (min-width: 1025px) and (max-width: 1300px) {
    .rb-container {
        margin-top: 10%;
        width: 100%;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            width: 800px;
        }

        &-content {
            padding: 0 5% 0 5%;
            display: flex;

            .info {
                margin-top: 18px;

                .description {
                    max-width: 560px;
                    color: #A5A5A5;
                    
                    &-block {
                        margin-bottom: 20px;
                    }

                    .download {
                        margin-top: 30px;
                        &-text {
                            color: white;
                        }
                        &-platforms {
                            margin-top: 20px;
                            &-item {
                                padding-right: 12%;
                                height: 60px;
                            }
                        }

                        .trade-now-btn {
                            margin-top: 30px;

                            position: relative;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            width: 147px;
                            padding: 18px 30px;
                            border-radius: 100px;
                            color: white;
                            background: #3F778A;
                        }
                    }
                }
            }
        }
            
        .map-glow {
            position: relative;
            width: 50%;
            margin: 4% 0 0 5%;

            .map {
                width: 100%;
                position: absolute;
            }
        }
    }
}



// =============    < 1024    =============


@media screen and (max-width: 1025px) and (min-width: 481px) {
    .rb-container {
        margin-top: 10%;
        width: 100%;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
        }
        
        &-content {
            width: 90%;
            padding: 0 5% 0 5%;
            position: relative;

            .info {
                position: relative;
                margin-top: 18px;
                width: 100%;

                .description {
                    color: #A5A5A5;
                    
                    &-first {
                        width: 60%;
                    }

                    &-second {
                        margin-left: 2%;
                        width: 50%;
                    }

                    &-block {
                        margin-bottom: 20px;
                    }

                    .download {
                        margin-top: 20px;
                        &-text {
                            color: white;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 22px;

                        }
                        &-platforms {
                            margin-top: 20px;
                            gap: 15%;
                            display: flex;

                            &-item {
                                height: 50px;
                            }
                        }

                        .trade-now-btn {
                            margin-top: 30px;

                            position: relative;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            width: 147px;
                            padding: 18px 30px;
                            border-radius: 100px;
                            color: white;
                            background: #3F778A;
                        }
                    }
                }
            }
        }
            
        .map-glow {
            width: 100%;
            margin-top: 50px;
            height: 400px;

            .map {
                width: 80%;
                display: block;
                margin: 0 auto 0 auto;
            }
        }
    }
}




// =============    < 480    =============


@media screen and (max-width: 481px) and (min-width: 0px) {
    .rb-container {
        margin-top: 120px;
        width: 100%;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 3% 0 3%;
            font-size: 36px;
            line-height: 46px;
        }
        
        &-content {
            width: 90%;
            padding: 0 3% 0 3%;
            position: relative;

            .info {
                position: relative;
                margin-top: 18px;
                width: 100%;

                .description {
                    color: #A5A5A5;
                    
                    &-first {
                        width: 100%;
                    }

                    &-second {
                        width: 100%;
                    }

                    &-block {
                        margin-bottom: 10px;
                        font-size: 14px;
                        line-height: 21px;
                    }

                    .download {
                        margin-top: 30px;
                        &-text {
                            color: white;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;

                        }
                        &-platforms {
                            margin-top: 20px;
                            gap: 12%;
                            display: flex;
                            justify-content: left;

                            &-item {
                                height: 50px;
                            }
                        }

                        .trade-now-btn {
                            margin-top: 30px;

                            position: relative;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            width: 147px;
                            padding: 18px 30px;
                            border-radius: 100px;
                            color: white;
                            background: #3F778A;
                        }
                    }
                }
            }
        }
            
        .map-glow {
            position: relative;
            width: 100%;
            margin-top: 50px;
            justify-content: center;

            .map {
                width: 90%;
                display: block;
                margin: 0 auto 0 auto;
            }
        }
    }
}
