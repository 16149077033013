.banner__wrap {
  display: flex;
  flex-wrap: wrap;
}

.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-start {
  align-items: start;
}

.h-100 {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.banner_title {
  font-family: 'Bebas';
  font-size: 86px;
  font-weight: 400;
  margin: 0;

  .banner__title__span {
    font-size: 64px;
  }
}

.pr-5 {
  padding-right: 50px;
}

.banner__text {
  font-family: 'Inter';
  font-size: 16px;
  color: #A5A5A5;
}

.banner__link {
  position: relative;
  display: inline-flex;
  font-family: "Inter";
  font-size: 16px;
  color: white;
  padding: 16px 30px;
  background: rgba(63, 119, 138, 1);
  border-radius: 100px;
  text-decoration: none;
  margin-top: 15px;
  cursor: pointer;
}

.banner-img__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .banner__images {
    position: fixed;
    .fb-icon {
      position: absolute;
      bottom: -100px;
      left: 41%;
    }
    .eth-icon {
      position: absolute;
      bottom: 55px;
      left: 125px;
    }
    .apple-icon {
      position: absolute;
      left: 100px;
      top: 65px;
    }
    .tesla-icon {
      position: absolute;
      top: 0;
      right: 25%;
    }
    .btc-icon {
      position: absolute;
      bottom: 0;
      right: 105px;
    }
    .anim-text {
      position: absolute;
      font-family: 'Inter', sans-serif;
      font-size: 32px;
      color: #222ca3;
      text-transform: uppercase;
      &.buy-text-0 {
        left: 52%;
        top: 70px;
      }
      &.buy-text-1 {
        right: 70px;
        bottom: 135px;
      }
      &.bid-text-1 {
        left: 37%;
        bottom: 70px;
      }
    }
    .sound-icon {
      right: 150px;
      bottom: 155px;
    }
    .figure {
      position: absolute;
      width: 38px;
      height: 10px;
      border: 1px solid #01F2D8;
      border-radius: 15px;
      &.figure-1 {
        left: 75px;
        bottom: 115px;
      }
      &.figure-2 {
        left: 48%;
        bottom: 90px;
      }
      &.figure-3 {
        left: 51%;
        bottom: 9px;
      }
      &.figure-4 {
        right: 130px;
        bottom: 110px;
      }
      &.figure-5 {
        right: 80px;
        top: 40px;
      }
      &.figure-6 {
        left: 56%;
        top: 140px;
      }
    }
    .circle {
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      &.blue {
        background-color: #0C4DCF;
      }
      &.light-blue {
        background-color: #01F2D8;
      }
      &.circle-1 {
        left: 40%;
        top: 95px;
      }
      &.circle-2 {
        right: 42%;
        top: 15px;
      }
      &.circle-3 {
        right: 16%;
        bottom: 88px;
      }
      &.circle-4 {
        left: 40%;
        bottom: 30px;
      }
      &.circle-5 {
        right: 38%;
        top: 120px;
      }
    }
  }
}

.banner-bg__overlay {
  position: relative;
  background-image: url("../../../assets/about-banner__overlay.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 560px;
  width: 100%;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(1, 242, 216, 0.4);
    filter: blur(300px);
    opacity: .5;
  }
}

@media (min-width: 1400px) {
  .d-xxl-block {
    display: block!important;
  }
}

@media (max-width: 768px) {
  .col-12 {
    width: 100%;
  }
  .pr-5 {
    padding-right: 0px;
  }

}

@media (max-width: 480px) {
  .col-md-6 {
    margin-bottom: 50px;
  }
  .banner__wrap {
    padding: 0px!important;
  }
  .banner_title {
    font-size: 36px!important;
  }
  .banner__title__span {
    font-size: 36px!important;
  }
}
