.sf-container-content-item-text {
    color: black;
}

@media screen and (min-width: 1541px) {
    .sf-container {
        width: 100%;
        margin: 180px 0;
    
        .sf-container-header {
            padding: 0 5%;
            width: 90%;
            text-align: center;
        }
    
        &-content {
            padding: 0 5%;
            width: 90%;
            justify-content: center;
            justify-items: center;
            margin-top: 60px;
    
            display: grid;
            grid-template: repeat(1, 0fr) / repeat(5, 1fr);
    
            &-item {
                width: 280px;
                text-align: center;
                justify-content: center;
                &-img {
                    text-align: center;
                    img {
                        height: 87px;
                    }
                }
                &-text {
                    margin-top: 30px;
                    text-align: center;
                }
            }
            &-item:hover img {
                scale: 1.1;
            }
        }
    }
}






@media screen and (min-width: 1025px) and (max-width: 1540px) {
    .sf-container {
        width: 100%;
        margin: 180px 0;
    
        .sf-container-header {
            padding: 0 5%;
            width: 90%;
            text-align: center;
        }
    
        &-content {
            padding: 0 5%;
            width: 90%;
            justify-content: center;
            justify-items: center;
            margin-top: 60px;
    
            display: grid;
            gap: 40px 0;
            grid-template-columns: repeat(3, 1fr);
            .box-3 {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 2;
            }
            .box-4 {
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 2;
            }
    
            &-item {
                width: 280px;
                text-align: center;
                justify-content: center;
                &-img {
                    text-align: center;
                    img {
                        height: 87px;
                    }
                }
                &-text {
                    margin-top: 30px;
                    text-align: center;
                }
            }
            &-item:hover img {
                scale: 1.1;
            }
        }
    }
}






@media screen and (min-width: 601px) and (max-width: 1024px) {
    .sf-container {
        width: 100%;
        margin: 180px 0;
    
        .sf-container-header {
            padding: 0 5%;
            width: 90%;
            text-align: center;
        }
    
        &-content {
            padding: 0 5%;
            width: 90%;
            justify-content: center;
            justify-items: center;
            margin-top: 60px;
    
            display: grid;
            grid-template: repeat(5, 1fr) / repeat(1, 1fr);
            gap: 30px 0;
    
            &-item {
                width: 280px;
                text-align: center;
                justify-content: center;
                &-img {
                    text-align: center;
                    img {
                        height: 87px;
                    }
                }
                &-text {
                    margin-top: 30px;
                    text-align: center;
                }
            }
            &-item:hover img {
                scale: 1.1;
            }
        }
    }
}






@media screen and (min-width: 481px) and (max-width: 600px) {
    .sf-container {
        width: 100%;
        margin: 180px 0;
    
        .sf-container-header {
            padding: 0 5%;
            width: 90%;
            text-align: center;
        }
    
        &-content {
            padding: 0 5%;
            width: 90%;
            justify-content: center;
            justify-items: center;
            margin-top: 60px;
    
            display: grid;
            grid-template: repeat(5, 1fr) / repeat(1, 1fr);
            gap: 30px 0;
            &-item {
                width: 220px;
                text-align: center;
                justify-content: center;
                &-img {
                    text-align: center;
                    img {
                        height: 87px;
                    }
                }
                &-text {
                    margin-top: 30px;
                    text-align: center;
                }
            }
            &-item:hover img {
                scale: 1.1;
            }
        }
    }
}






@media screen and (min-width: 271px) and (max-width: 480px) {
    .sf-container {
        width: 100%;
        margin: 100px 0;
    
        .sf-container-header {
            padding: 0 3%;
            width: 94%;
            text-align: center;
            font-size: 36px;
            line-height: 46px;
        }
    
        &-content {
            padding: 0 3%;
            width: 94%;
            justify-content: center;
            justify-items: center;
            margin-top: 50px;
    
            display: grid;
            grid-template: repeat(5, 1fr) / repeat(1, 1fr);
            gap: 20px 0;
    
            &-item {
                width: 250px;
                text-align: center;
                justify-content: center;
                &-img {
                    text-align: center;
                    img {
                        height: 87px;
                    }
                }
                &-text {
                    text-align: center;
                    font-size: 20px!important;
                    line-height: 25px;
                }
            }
            &-item:hover img {
                scale: 1.1;
            }
        }
    }
}






@media screen and (min-width: 0px) and (max-width: 270px) {
    .sf-container {
        width: 100%;
        margin: 150px 0;
    
        .sf-container-header {
            padding: 0 3%;
            width: 94%;
            text-align: center;
            font-size: 36px;
            line-height: 46px;
        }
    
        &-content {
            padding: 0 3%;
            width: 94%;
            justify-content: center;
            justify-items: center;
            margin-top: 50px;
    
            display: grid;
            grid-template: repeat(5, 1fr) / repeat(1, 1fr);
            gap: 20px 0;
    
            &-item {
                width: 100%;
                text-align: center;
                justify-content: center;
                &-img {
                    text-align: center;
                    img {
                        height: 60px;
                    }
                }
                &-text {
                    text-align: center;
                    font-size: 20px!important;
                    line-height: 25px;
                }
            }
            &-item:hover img {
                scale: 1.1;
            }
        }
    }
}