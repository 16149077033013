.ta-right {
    text-align: right;
}
.ta-center {
    text-align: center;
}
.color-grey {
    color: rgba(255, 255, 255, 0.7);
}
th {
    color: rgba(255, 255, 255, 1);
}
.news-item-text {
    color: rgba(255, 255, 255, 1);
}

@media screen and (min-width: 1481px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            &-en {
                width: 650px;
            }
            &-it {
                width: 970px;
            }
            &-ru {
                width: 850px;
            }
        }

        &-content {
            padding: 40px 5% 0 5%;
            display: flex;
            height: 600px;

            &-tabs {
                width: 47%;
                padding: 20px 0 20px 0;
                position: relative;
                background: rgba(63, 119, 138, 1);
                border-radius: 16px;


                .tab {
                    padding: 2% 10% 2% 10%;
                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;
                    }

                    &-content {
                        display: none;
                        justify-content: right;

                        table {
                            border-collapse: separate;
                            border-spacing: 50px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 7px 7px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 5% 10% 0% 10%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                width: 47%;
                margin-left: 6%;
                padding-right: 10px;
                padding-top: 10px;
                padding: 10px 10px 10px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    height: 100%;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: rgba(255, 255, 255, 1);
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: rgba(255, 255, 255, 1);
                    }
                }

                .news-item {
                    display: flex;
                    margin: 0 5% 0 5%;
                    padding: 4% 0 4% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }
                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 10px;
                        width: 50px;
                    }
                    &-text {
                        width: 60%;
                        margin-left: 14%;
                    }
                }
            }
        }
    }
}





// =============   < 1480    ===============




@media screen and (max-width: 1481px) and (min-width: 1281px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            &-en {
                width: 650px;
            }
            &-it {
                width: 970px;
            }
            &-ru {
                width: 850px;
            }
        }

        &-content {
            padding: 40px 5% 0 5%;
            display: flex;
            height: 600px;

            &-tabs {
                width: 47%;
                padding: 20px 0 20px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .tab {
                    padding: 2% 10% 2% 10%;
                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;
                    }

                    &-content {
                        display: none;
                        justify-content: right;

                        table {
                            border-collapse: separate;
                            border-spacing: 50px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 7px 7px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 5% 0% 0% 10%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                width: 47%;
                margin-left: 6%;
                padding-right: 10px;
                padding-top: 10px;
                padding: 10px 10px 10px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    height: 100%;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    display: flex;
                    margin: 0 5% 0 5%;
                    padding: 4% 0 4% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }
                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 10px;
                        width: 50px;
                    }
                    &-text {
                        width: 60%;
                        margin-left: 14%;
                    }
                }
            }
        }
    }
}






// =========  <  1280  ==========




@media screen and (max-width: 1281px) and (min-width: 941px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            &-en {
                width: 650px;
            }
            &-it {
                width: 970px;
            }
            &-ru {
                width: 850px;
            }
        }

        &-content {
            padding: 40px 5% 0 5%;
            width: 90%;

            &-tabs {
                width: 100%;
                padding: 30px 0 50px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                height: 500px;              // here

                .tab {
                    padding: 15px 5% 15px 5%;

                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;
                    }

                    &-content {
                        display: none;
                        justify-content: center;

                        table {
                            border-collapse: separate;
                            border-spacing: 130px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 7px 7px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 3% 0% 0% 5%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                margin-top: 50px;
                width: 100%;
                padding: 10px 10px 10px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    // height: 100%;
                    height: 450px;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    display: flex;
                    margin: 0 5% 0 5%;
                    padding: 4% 0 4% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }
                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 5%;
                        width: 50px;
                    }
                    &-text {
                        width: 60%;
                        margin-left: 10%;
                    }
                }
            }
        }
    }
}





// =========  <  941  ==========




@media screen and (max-width: 941px) and (min-width: 641px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            width: 650px;
        }

        &-content {
            padding: 40px 5% 0 5%;
            width: 90%;

            &-tabs {
                width: 100%;
                padding: 30px 0 50px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                height: 500px;              // here

                .tab {
                    padding: 15px 5% 15px 5%;

                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;
                    }

                    &-content {
                        display: none;
                        justify-content: center;

                        table {
                            border-collapse: separate;
                            border-spacing: 65px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 7px 7px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 3% 0% 0% 5%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                margin-top: 50px;
                width: 100%;
                padding: 10px 10px 10px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    // height: 100%;
                    height: 450px;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    display: flex;
                    margin: 0 5% 0 5%;
                    padding: 4% 0 4% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }
                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 5%;
                        width: 50px;
                    }
                    &-text {
                        width: 60%;
                        margin-left: 10%;
                    }
                }
            }
        }
    }
}






// =========  <  641  ==========




@media screen and (max-width: 641px) and (min-width: 481px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 5% 0 5%;
            width: 90%;
        }

        &-content {
            padding: 40px 5% 0 5%;
            width: 90%;

            &-tabs {
                width: 100%;
                padding: 30px 0 50px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                height: 500px;              // here

                .tab {
                    padding: 15px 5% 15px 5%;

                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;
                    }

                    &-content {
                        display: none;
                        justify-content: center;

                        table {
                            border-collapse: separate;
                            border-spacing: 30px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 7px 7px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 3% 0% 0% 5%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                margin-top: 50px;
                width: 100%;
                padding: 10px 10px 10px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    // height: 100%;
                    height: 450px;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    margin: 0 5% 0 5%;
                    padding: 6% 0 6% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }


                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 5%;
                        width: 50px;
                    }
                    &-text {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}






// =========  <  480  ==========




@media screen and (max-width: 481px) and (min-width: 361px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 3% 0 3%;
            width: 94%;

            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 40px 3% 0 3%;
            width: 94%;

            &-tabs {
                width: 100%;
                padding: 30px 0 50px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                height: 460px;              // here
                .tab {
                    padding: 15px 3% 15px 3%;

                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;

                        font-size: 16px;
                        line-height: 19px;
                    }

                    &-content {
                        display: none;
                        justify-content: center;

                        table {
                            border-collapse: separate;
                            border-spacing: 30px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 6px 6px;
                        }

                        .tr-m {
                            font-size: 14px;
                        }

                        .td-m {
                            font-size: 12px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 3% 0% 0% 3%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                margin-top: 50px;
                width: 94%;
                padding: 10px 3% 10px 3%;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    // height: 100%;
                    height: 450px;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    margin: 0 5% 0 5%;
                    padding: 6% 0 6% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }


                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 5%;
                        width: 50px;
                    }
                    &-text {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}






// =========  <  361  ==========




@media screen and (max-width: 361px) and (min-width: 321px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 3% 0 3%;
            width: 94%;

            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 40px 3% 0 3%;
            width: 94%;

            &-tabs {
                width: 100%;
                padding: 30px 0 50px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                height: 460px;              // here

                .tab {
                    padding: 15px 3% 15px 3%;

                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;

                        font-size: 16px;
                        line-height: 19px;
                    }

                    &-content {
                        display: none;
                        justify-content: center;

                        table {
                            border-collapse: separate;
                            border-spacing: 15px 20px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 6px 6px;
                        }

                        .tr-m {
                            font-size: 14px;
                        }

                        .td-m {
                            font-size: 12px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 3% 0% 0% 3%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                margin-top: 50px;
                width: 94%;
                padding: 10px 3% 10px 3%;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    // height: 100%;
                    height: 450px;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    margin: 0 5% 0 5%;
                    padding: 6% 0 6% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }


                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 5%;
                        width: 50px;
                    }
                    &-text {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}





// =========  <  321  ==========




@media screen and (max-width: 321px) and (min-width: 0px) {
    .mn-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 3;

        .header {
            padding: 0 3% 0 3%;
            width: 94%;

            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 40px 3% 0 3%;
            width: 94%;

            &-tabs {
                width: 100%;
                padding: 30px 0 50px 0;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                height: 400px;              // here

                .tab {
                    padding: 15px 3% 15px 3%;

                    &-header {
                        color: rgba(255, 255, 255, 1);
                        font-weight: 200;
                        cursor: pointer;

                        font-size: 16px;
                        line-height: 19px;
                    }

                    &-content {
                        display: none;
                        justify-content: center;

                        table {
                            border-collapse: separate;
                            border-spacing: 10px 10px;
                        }

                        .spread-item {
                            color: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(255, 255, 255, 1);
                            border-radius: 4px;
                            padding: 6px 6px;
                        }

                        .tr-m {
                            font-size: 14px;
                        }

                        .td-m {
                            font-size: 12px;
                        }
                    }

                    .show-content {
                        display: flex;
                    }
                }

                .selected-tab {
                    background: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
                    padding: 3% 0% 0% 3%;
                }
                .selected-tab .tab-header {
                    font-weight: 700;
                }
            }

            &-news {
                margin-top: 50px;
                width: 94%;
                padding: 10px 3% 10px 3%;
                position: relative;
                background: #3F778A;
                border-radius: 16px;

                .overflow-scroll {
                    // height: 100%;
                    height: 450px;
                    scroll-behavior: auto;
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 16px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 16px;
                        background-color: rgba(63, 119, 138, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        border-radius: 16px;
                        background: white;
                    }
                }

                .news-item {
                    margin: 0 5% 0 5%;
                    padding: 6% 0 6% 0;
                    border-style: solid;
                    border-width: 1px;

                    border-image: linear-gradient(
                        to left,
                        rgba(63, 119, 138, 1),
                        rgba(242, 242, 242, 0.5),
                        rgba(63, 119, 138, 1)
                    ) 100% 0 100% 0/0 0 1px 0 stretch;

                    &-datetime {
                        display: flex;
                    }


                    &-date {
                        width: 100px;
                    }
                    &-time {
                        margin-left: 5%;
                        width: 50px;
                    }
                    &-text {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}