@media screen and (min-width: 1601px) {
    .twt-container {
        width: 100%;
        padding: 0 5% 0 5%;
        margin-top: 150px;
        position: relative;
        z-index: 2;

        .header {
            width: 600px;
            &-anlang {
                width: 800px;
            }
        }

        &-content {
            display: flex;
            position: relative;

            &-info {
                width: 40%;

                .description {
                    color: rgba(81, 81, 81, 1);
                    margin-top: 40px;
                    width: 74%;
                }

                .second {
                    margin-top: 20px;
                    font-weight: 700;
                }
                
                .open-live-account {
                    position: relative;
                    margin-top: 40px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 30px;
                    // width: 150px;

                    background: #3F778A;
                    color: white;
                    border-radius: 100px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    cursor: pointer;
                }
            }

            &-charts {
                margin-top: 0px;
                margin-left: 180px;
                position: relative;

                img {
                    width: 600px;
                    position: absolute;
                }
                
                #charts {
                    opacity: 1;
                }
            }
        }
    }
}




// =============    < 1601    ==============



@media screen and (max-width: 1601px) and (min-width: 1281px) {
    .twt-container {
        width: 100%;
        padding: 0 5% 0 5%;
        margin-top: 150px;
        position: relative;
        z-index: 2;

        .header {
            width: 600px;
            &-anlang {
                width: 800px;
            }
        }

        &-content {
            display: flex;
            position: relative;

            &-info {
                width: 40%;
                
                .description {
                    color: rgba(81, 81, 81, 1);
                    margin-top: 40px;
                    width: 560px;
                }

                .second {
                    margin-top: 20px;
                    font-weight: 700;
                }
                
                .open-live-account {
                    position: relative;
                    margin-top: 40px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 30px;

                    background: #3F778A;
                    color: white;
                    border-radius: 100px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    cursor: pointer;
                }
            }

            &-charts {
                margin-top: 70px;
                margin-left: 260px;
                position: relative;

                img {
                    width: 500px;
                    position: absolute;
                }
                
                #charts {
                    opacity: 1;
                }
            }
        }
    }
}






// =============    < 1281    ==============



@media screen and (max-width: 1281px) and (min-width: 1025px) {
    .twt-container {
        width: 100%;
        padding: 0 5% 0 5%;
        margin-top: 150px;
        position: relative;
        z-index: 2;

        .header {
            width: 600px;
            &-anlang {
                width: 800px;
            }
        }

        &-content {
            display: flex;
            position: relative;

            &-info {
                width: 40%;
                
                .description {
                    color: rgba(81, 81, 81, 1);
                    margin-top: 40px;
                    width: 100%;
                }

                .second {
                    margin-top: 20px;
                    font-weight: 700;
                }
                
                .open-live-account {
                    position: relative;
                    margin-top: 40px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 30px;

                    background: #3F778A;
                    color: white;
                    border-radius: 100px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    cursor: pointer;
                }
            }

            &-charts {
                margin-top: 100px;
                margin-left: 10%;
                position: relative;

                img {
                    width: 450px;
                    position: absolute;
                }
                
                #charts {
                    opacity: 1;
                }
            }
        }
    }
}





// =============    < 1024    ==============



@media screen and (max-width: 1025px) and (min-width: 481px) {
    .twt-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 2;

        .header {
            width: 90%;
            padding: 0 5% 0 5%;
        }

        &-content {
            padding: 0 5% 0 5%;
            position: relative;

            &-info {
                width: 100%;
                // background-color: blue;
                
                .description {
                    color: rgba(81, 81, 81, 1);
                    margin-top: 40px;
                    width: 440px;
                }

                .second {
                    margin-top: 20px;
                    font-weight: 700;
                }
                
                .open-live-account {
                    position: relative;
                    margin-top: 40px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 30px;

                    background: #3F778A;
                    color: white;
                    border-radius: 100px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    cursor: pointer;
                }
            }

            &-charts {
                margin-top: 70px;
                // margin-left: 10%;
                position: relative;
                // width: 100%;

                img {
                    margin: 0 auto 0 auto;
                    width: 70%;
                    display: block;
                    position: relative;
                }
                
                #charts {
                    opacity: 1;
                }
            }
        }
    }
}





// =============    < 480    ==============



@media screen and (max-width: 481px) and (min-width: 321px) {
    .twt-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 2;

        .header {
            width: 90%;
            padding: 0 3% 0 3%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3% 0 3%;
            position: relative;

            &-info {
                width: 100%;
                // background-color: blue;
                
                .description {
                    color: rgba(81, 81, 81, 1);
                    margin-top: 30px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 21px;
                }

                .second {
                    margin-top: 40px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                }
                
                .open-live-account {
                    margin-top: 25px;

                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 30px;

                    background: #3F778A;
                    color: white;
                    border-radius: 100px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    cursor: pointer;
                }
            }

            &-charts {
                margin-top: 70px;
                // margin-left: 10%;
                position: relative;
                // width: 100%;

                img {
                    margin: 0 auto 0 auto;
                    width: 100%;
                    display: block;
                    position: relative;
                }
                
                #charts {
                    opacity: 1;
                }
            }
        }
    }
}





// =============    < 320    ==============



@media screen and (max-width: 321px) and (min-width: 0px) {
    .twt-container {
        width: 100%;
        margin-top: 150px;
        position: relative;
        z-index: 2;

        .header {
            width: 90%;
            padding: 0 3% 0 3%;
            font-size: 36px;
            line-height: 46px;
        }

        &-content {
            padding: 0 3% 0 3%;
            position: relative;

            &-info {
                width: 100%;
                
                .description {
                    color: rgba(81, 81, 81, 1);
                    margin-top: 20px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 21px;
                }

                .second {
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                }
                
                .open-live-account {
                    margin-top: 15px;
                    width: 90%;

                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px 5px;

                    background: #3F778A;
                    color: white;
                    border-radius: 100px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;

                    cursor: pointer;

                    
                }
            }

            &-charts {
                margin-top: 30px;
                position: relative;

                img {
                    margin: 0 auto 0 auto;
                    width: 100%;
                    display: block;
                    position: relative;
                }
                
                #charts {
                    opacity: 1;
                }
            }
        }
    }
}