img {
    transition: scale .5s;
}

@media screen and (min-width: 1601px) {
    .ta-container {
        width: 100%;
        margin: 150px 0 150px 0;
        position: relative;
        display: flex;

        &-wrapper {
            width: 50%;
            padding: 0 5% 0 5%;

            .ta-container-content {
                position: relative;
                margin-top: 40px;

                .par {
                    color: #A5A5A5;
                    margin-bottom: 15px;
                    width: 70%;
                }

                &-second {
                    .download {
                        margin-top: 40px;
                        display: flex;
                        gap: 30px;
                        img {
                            width: 180px;
                            height: 57px;
                            cursor: pointer;
                        }
                        img:hover {
                            scale: 1.2;
                        }
                    }
                }
            }
        }

        &-images {
            position: relative;
            width: 40%;
            height: 100%;
            margin-left: 0;

            img {
                position: absolute;
            }

            &-phones {
                width: 400px;
                z-index: 10;
                left: 20%;
            }

            &-graph {
                width: 100%;
                z-index: 9;
                right: 0;
            }
        }
    }
}





// ===============   < 1600   ================



@media screen and (max-width: 1601px) and (min-width: 1281px) {
    .ta-container {
        width: 100%;
        margin: 150px 0 200px 0;
        position: relative;
        display: flex;

        &-wrapper {
            width: 60%;
            padding: 0 0% 0 5%;

            .ta-container-content {
                position: relative;
                margin-top: 40px;

                .par {
                    color: #A5A5A5;
                    margin-bottom: 15px;
                    width: 70%;
                }

                &-second {
                    .download {
                        margin-top: 40px;
                        display: flex;
                        gap: 30px;
                        img {
                            width: 180px;
                            height: 60px;
                            cursor: pointer;
                        }
                        img:hover {
                            scale: 1.2;
                        }
                    }
                }
            }
        }

        &-images {
            position: relative;
            width: 40%;
            height: 100%;
            margin-left: 0;
            margin-top: 60px;

            img {
                position: absolute;
            }
            &-phones {
                width: 300px;
                z-index: 10;
                left: 20%;
            }
            &-graph {
                width: 120%;
                z-index: 9;
                right: 0;
            }
        }
    }
}





// ===============   < 1280   ================



@media screen and (max-width: 1281px) and (min-width: 1025px) {
    .ta-container {
        width: 100%;
        margin: 150px 0 200px 0;
        position: relative;
        display: flex;

        &-wrapper {
            width: 60%;
            padding: 0 0% 0 5%;

            .ta-container-header {
                width: 800px;
            }
            .ta-container-content {
                position: relative;
                margin-top: 40px;
                .par {
                    color: #A5A5A5;
                    margin-bottom: 15px;
                    width: 70%;
                }

                &-second {
                    .download {
                        margin-top: 40px;
                        display: flex;
                        gap: 30px;

                        img {
                            width: 180px;
                            height: 57px;
                            cursor: pointer;
                        }
                        img:hover {
                            scale: 1.2;
                        }
                    }
                }
            }
        }

        &-images {
            position: relative;
            width: 40%;
            height: 100%;
            margin-left: 0;
            margin-top: 90px;

            img {
                position: absolute;
            }
            &-phones {
                width: 250px;
                z-index: 10;
                left: 20%;
            }
            &-graph {
                width: 120%;
                z-index: 9;
                right: 0;
            }
        }
    }
}





// ===============   < 1025   ================



@media screen and (max-width: 1025px) and (min-width: 769px) {
    .ta-container {
        width: 100%;
        margin: 150px 0 0px 0;
        position: relative;

        &-wrapper {
            width: 100%;
            padding: 0 5% 0 5%;
            position: relative;

            .ta-container-header {
                width: 90%;
            }
            .ta-container-content {
                position: relative;
                margin-top: 30px;
                display: flex;
                width: 90%;

                .par {
                    color: #A5A5A5;
                    margin-bottom: 15px;
                    width: 100%;
                }
                &-first {
                    width: 49%;
                }
                &-second {
                    width: 49%;
                    margin-left: 2%;
                    .download {
                        margin-top: 30px;
                        display: flex;
                        gap: 10%;
                        img {
                            width: 160px;
                            height: 54px;
                            cursor: pointer;
                        }
                        img:hover {
                            scale: 1.2;
                        }
                    }
                }
            }
        }

        &-images {
            position: relative;
            width: 100%;
            height: 600px;
            margin-left: 0;
            margin-top: 50px;
            justify-content: center;
            img {
                position: absolute;
            }
            &-phones {
                width: 330px;
                z-index: 10;
                left: calc(50% - 165px);
                margin-top: 30px;
            }
            &-graph {
                width: 100%;
                z-index: 9;
                right: 0;
            }
        }
    }
}





// ===============   < 768   ================



@media screen and (max-width: 769px) and (min-width: 481px) {
    .ta-container {
        width: 100%;
        margin: 130px 0 100px 0;
        position: relative;

        &-wrapper {
            width: 100%;
            padding: 0 5% 0 5%;
            position: relative;
            .ta-container-header {
                width: 90%;
            }
            .ta-container-content {
                position: relative;
                margin-top: 30px;
                display: flex;
                width: 90%;
                .par {
                    color: #A5A5A5;
                    margin-bottom: 15px;
                    width: 100%;
                }
                &-first {
                    width: 49%;
                }
                &-second {
                    width: 49%;
                    margin-left: 2%;
                    .download {
                        margin-top: 30px;
                        justify-content: center;
                        img {
                            margin-left: 20%;
                            width: 170px;
                            height: 55px;
                            cursor: pointer;
                            margin-bottom: 20px;
                        }
                        img:hover {
                            scale: 1.2;
                        }
                    }
                }
            }
        }

        &-images {
            position: relative;
            width: 100%;
            height: 600px;
            margin-left: 0;
            margin-top: 50px;
            justify-content: center;
            img {
                position: absolute;
            }
            &-phones {
                width: 330px;
                z-index: 10;
                left: calc(50% - 165px);
                margin-top: 30px;
            }
            &-graph {
                width: 100%;
                z-index: 9;
                right: 0;
            }
        }
    }
}






// ===============   < 480   ================



@media screen and (max-width: 481px) and (min-width: 0px) {
    .ta-container {
        width: 100%;
        margin: 130px 0 0px 0;
        position: relative;

        &-wrapper {
            width: 100%;
            padding: 0 5% 0 5%;
            position: relative;

            .ta-container-header {
                width: 90%;
                font-size: 36px;
                line-height: 46px;
            }

            .ta-container-content {
                position: relative;
                margin-top: 30px;
                width: 90%;
                .par {
                    color: #A5A5A5;
                    margin-bottom: 15px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 21px;
                }
                &-first {
                    width: 100%;
                }
                &-second {
                    width: 100%;

                    .download {
                        margin-top: 30px;
                        display: flex;
                        gap: 10%;
                        justify-content: center;
                        img {
                            width: 100%;
                            cursor: pointer;
                        }
                        img:hover {
                            scale: 1.2;
                        }
                    }
                }
            }
        }

        &-images {
            position: relative;
            width: 100%;
            height: 500px;
            margin-left: 0;
            margin-top: 50px;
            justify-content: center;
            img {
                position: absolute;
            }
            &-phones {
                width: 270px;
                z-index: 10;
                left: calc(50% - 135px);
            }
            &-graph {
                width: 100%;
                z-index: 9;
                right: 0;
                margin-top: 70px;
            }
        }
    }
}