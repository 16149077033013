.left-block-header {
    color: white
}
.left-block-description {
    color: rgba(255, 255, 255, 0.7);
}

@media screen and (min-width: 1501px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs-cfds-container-header {
            background-color: transparent;
            padding: 100px 5% 40px 5%;
        }

        .tabs {
            .tab {
                padding: 0 5% 0 5%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 0.5% 0 0.5% 0;
                    cursor: pointer;
                } 
                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 35%;
                        &-header {
                            width: 550px;
                            margin-bottom: 25px;
                            font-size: 48px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 470px;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 18px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        margin-left: auto;
                        margin-right: 3%;
                        &-item {
                            display: flex;
                            .item-column {
                                margin-right: 50px;
                                color: #A5A5A5;
                                font-weight: 400;
                            }
                        }
                        .table-column {
                            padding-left: 100px;
                            height: 70px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: flex;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 1% 5% 1% 5%;

                .tab-header {
                    color: rgba(84, 222, 253, 1);
                }
            }
        }
    }
}







// ==================  < 1500 ======================


@media screen and (max-width: 1500px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs-cfds-container-header {
            background-color: transparent;
            padding: 100px 5% 40px 5%;
        }

        .tabs {
            .tab {
                padding: 0 5% 0 5%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 0.5% 0 0.5% 0;
                    cursor: pointer;
                } 

                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 35%;
                        &-header {
                            width: 550px;
                            margin-bottom: 25px;
                            font-size: 48px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 470px;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 18px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        margin-left: auto;
                        &-item {
                            display: flex;

                            .item-column {
                                margin-right: 40px;
                                color: #A5A5A5;
                                font-weight: 400;
                            }
                        }
                        .table-column {
                            padding-left: 50px;
                            height: 70px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: flex;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 1% 5% 1% 5%;
                .tab-header {
                    color: rgba(84, 222, 253, 1);
                }
            }
        }
    }
}





// ==================  < 1180 ======================


@media screen and (max-width: 1181px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs {
            .tab {
                padding: 0 5% 0 5%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 0.5% 0 0.5% 0;
                    cursor: pointer;
                } 

                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 35%;
                        &-header {
                            width: 370px;
                            margin-bottom: 25px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 350px;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 18px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        margin-left: auto;
                        &-item {
                            display: flex;
                            .item-column {
                                margin-right: 40px;
                                color: #A5A5A5;
                                font-weight: 400;
                            }
                        }
                        .table-column {
                            height: 70px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: flex;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 1% 5% 1% 5%;
                .tab-header {
                    color: rgba(84, 222, 253, 1);
                }
            }
        }
    }
}






// ==================  < 1024 ======================


@media screen and (max-width: 1025px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs {
            .tab {
                padding: 0 5% 0 5%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 2% 0 2% 0;
                    cursor: pointer;
                } 
                &-content {
                    display: none;
                    margin-top: 20px;
                    .left-block {
                        width: 100%;
                        &-header {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 50%;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 18px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        width: 100%;
                        table {
                            width: 100%;
                        }
                        .table-column {
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                        }
                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: block;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 1% 5% 1% 5%;
                .tab-header {
                    padding-top: 40px;
                    color: rgba(84, 222, 253, 1);
                }
                .tab-content {
                    padding-bottom: 40px;
                    .right-block {
                        padding-top: 60px;
                    }
                }
            }
        }
    }
}





// ==================  < 768 ======================


@media screen and (max-width: 769px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs {
            .tab {
                padding: 0 5% 0 5%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 2% 0 2% 0;
                    cursor: pointer;
                } 

                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 100%;

                        &-header {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 100%;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 16px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        width: 100%;

                        table {
                            width: 100%;
                        }
                        .table-column {
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                            .icon-img {
                                height: 25px;
                            }
                            .graph-img {
                                width: 150px;
                            }
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: block;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 1% 5% 1% 5%;
                .tab-header {
                    padding-top: 40px;
                    color: rgba(84, 222, 253, 1);
                }
                .tab-content {
                    padding-bottom: 40px;
                    .right-block {
                        padding-top: 60px;
                    }
                }
            }
        }
    }
}






// ==================  < 600 ======================


@media screen and (max-width: 635px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs-cfds-container-header {
            background-color: transparent;
            padding: 60px 5% 20px 5%;
        }

        .tabs {
            .tab {
                padding: 0 5% 0 5%;
                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 2% 0 2% 0;
                    cursor: pointer;
                } 
                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 100%;
                        &-header {
                            width: 100%;
                            margin-bottom: 25px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 100%;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 16px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        width: 100%;
                        table {
                            width: 100%;
                        }
                        .table-column {
                            padding-left: 10px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                            font-size: 16px;
                            line-height: 17px;
                            .icon-img {
                                height: 25px;
                            }
                            .graph-img {
                                width: 150px;
                            }
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: block;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 1% 5% 1% 5%;
                .tab-header {
                    padding-top: 40px;
                    color: rgba(84, 222, 253, 1);
                }
                .tab-content {
                    padding-bottom: 40px;
                    .right-block {
                        padding-top: 60px;
                    }
                }
            }
        }
    }
}





// ==================  < 480 ======================

@media (max-width: 600px) {
    .tabs-cfds-container {
        .right-block {
            table {
                width: 100%!important;
            }
        }
    }
}

@media screen and (max-width: 481px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs-cfds-container-header {
            background-color: transparent;
            padding: 60px 3% 10px 3%;
            font-size: 36px;
            line-height: 40px;
        }

        .tabs {
            .tab {
                padding: 0 3% 0 3%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 2% 0 2% 0;
                    cursor: pointer;
                    font-size: 16px;
                    line-height: 19px;
                } 
                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 100%;
                        &-header {
                            width: 100%;
                            margin-bottom: 0px;
                            font-size: 32px;
                            line-height: 40px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 100%;
                            font-size: 14px;
                            line-height: 21px;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 16px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        width: 100%;
                        table {
                            width: 100%;
                        }
                        .table-column {
                            padding-left: 10px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                            font-size: 14px;
                            line-height: 17px;
                            height: 50px;

                            .icon-img {
                                height: 20px;
                            }
                            .graph-img {
                                width: 120px;
                            }
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }

                .show-content {
                    display: block;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 0% 3% 0% 3%;

                .tab-header {
                    padding-top: 40px;
                    color: rgba(84, 222, 253, 1);
                }
                .tab-content {
                    padding-bottom: 40px;
                    .right-block {
                        padding-top: 40px;
                    }
                }
            }
        }
    }
}






// ==================  < 350 ======================


@media screen and (max-width: 351px) {
    .tabs-cfds-container {
        width: 100%;
        height: 100%;
        z-index: 10;

        .tabs {
            .tab {
                padding: 0 3% 0 3%;

                &-header {
                    color: rgba(63, 119, 138, 1);
                    padding: 2% 0 2% 0;
                    cursor: pointer;
                    font-size: 16px;
                    line-height: 19px;
                } 

                &-content {
                    display: none;
                    margin-top: 20px;

                    .left-block {
                        width: 100%;
                        &-header {
                            width: 100%;
                            margin-bottom: 0px;
                            font-size: 32px;
                            line-height: 40px;
                        }
                        &-description {
                            font-weight: 400;
                            width: 100%;
                            font-size: 14px;
                            line-height: 21px;
                        }
                        &-button {
                            position: relative;
                            margin-top: 40px;
                            font-weight: 400;
                            padding: 16px 30px;
                            display: inline-flex;
                            border-radius: 100px;
                            color: black;
                            background-color: white;
                            box-shadow: 0px 4px 6px #2F5C6B;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .right-block {
                        width: 100%;
                        table {
                            width: 100%;
                        }
                        .table-column {
                            padding-left: 0px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                            text-align: center;
                            font-size: 12px;
                            line-height: 17px;
                            height: 40px;

                            .icon-img {
                                height: 18px;
                            }
                            .graph-img {
                                width: 100px;
                            }
                        }

                        .color-green {
                            color: rgba(113, 247, 159, 1);
                        }
                        .color-red {
                            color: rgba(255, 161, 160, 1);
                        }
                    }
                }
                .show-content {
                    display: block;
                }
            }

            .selected-tab {
                background: rgba(63, 119, 138, 1);
                font-weight: 700;
                padding: 0% 3% 0% 3%;

                .tab-header {
                    padding-top: 40px;
                    color: rgba(84, 222, 253, 1);
                }
                .tab-content {
                    padding-bottom: 40px;
                    .right-block {
                        padding-top: 40px;
                    }
                }
            }
        }
    }
}