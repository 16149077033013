.sel-all-text {
    color: black!important;
}
.sel-all-line {
    background-color: #3F778A!important;
}

.search-container {
    padding: 0 5%;
    &-search {
        display: flex;
        justify-content: space-between;
        .search-col {
            width:47%;
        }
        &-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            margin: auto 0;
            color: #000000;
        }
        &-input {
            display: flex;
            justify-content: space-between;
            input {
                width: calc(100% - 280px);
                height: 50px;
                border: 1px solid #DCDCDC;
                border-radius: 100px;
                background: url('../../../assets/earnings-calendar/loupe.png') no-repeat;
                background-position: 15px 50%;
                padding-left: 50px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: black;
            }
            input::placeholder {
                color: #A3A3A3;
            }
            .dropdown-component {
                border: 1px solid #DCDCDC;
                border-radius: 100px;
                width: 210px;
                margin-left: 10px;
                .Dropdown-root {
                    vertical-align: middle;
                }
                .Dropdown-control {
                    text-transform: none;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                    padding-left: 15px;
                    top: 9px;
                }
                .Dropdown-control:hover {
                    box-shadow: none;
                }
                .Dropdown-option {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                }
            }
            .dropdown {
                color: black;
            }
        }
    }
    
    &-select {
        margin: 40px 0 0px 0;
        display: flex;
        &-item {
            cursor: pointer;
            width: 50%;
            text-align: center;
            
            &-text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #A3A3A3;
            }
            &-line {
                height: 4px;
                margin-top: 3px;
                background-color: #C1C1C1;
            }
        }
    }
}


@media (max-width: 1025px) {
    .search-col {
        width: 100%!important;
    }
    .search-container-search {
        display: block;
    }
    .search-container-search-input {
        margin-top: 20px;
        input {
            width: calc(100% - 54px - 220px);
        }
    }
    .dropdown-component {
        margin-left: auto!important;
    }
    .Dropdown-control {
        height: 50px;
    }
}

@media (max-width: 481px) {
    .search-container {
        padding: 0 3%;
    }
    .dropdown-component {
        width: 100%!important;
        margin-top: 10px;
    }
    .search-container-select {
        margin-top: 20px;
    }
    .search-container-search-input {
        display: block;
        input {
            width: calc(100% - 54px);
        }
    }
}